import React from 'react';
import './../styles/SingleLetterViewPage.css';
import './../App.css';
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';

class SingleLetterView extends React.Component {
    render() {
        let letter = (this.props.letter && (this.props.letter.length > 0)) ? this.props.letter[0] : undefined;
        if (!letter) {
            // also need to handle trying to access a letter that they are not authorized to view publicly
            return (
                <div className="empty-letters-page-container error-loading">
                    <div>Letter not found :/</div>
                    <div className="empty-letters-page-icon">
                        <Tooltip title="Error">
                            <ErrorIcon sx={{ fontSize: 30 }}/>
                        </Tooltip>
                    </div>
                </div>
            )
        }
        return (
            <div className="letter-container">
                <div className="letter-view" style={{ backgroundImage: `url(${letter.stationaryId})` }}>
                    <div className="letter-content" style={{ fontFamily: `${letter.fontFamily}` }}>
                        <div>
                            {letter.body}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default SingleLetterView;