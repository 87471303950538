import React from 'react';
import './../styles/RemindersPage.css';
import './../App.css';
import AppHeader from './../components/AppHeader';
import VerticalMenu from '../components/VerticalMenu';

class RemindersPage extends React.Component {
    render() {
        return (
            <div>
                <AppHeader></AppHeader>
                <div className="letter-view-container">
                    <VerticalMenu></VerticalMenu>
                    This is the reminders page
                </div>
            </div>
        )
    }
}

export default RemindersPage;
