import React from 'react';
import './../styles/SingleLetterViewPage.css';
import './../App.css';
import appLogo from "../img/WriteNow-logos.jpeg";
import CircularProgress from '@mui/material/CircularProgress';
import SingleLetterView from '../components/SingleLetterVIew';

class PublicSingleLetterViewPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            letter: [],
            isLoadingPage: true
        };
        
        this.loadLetter = this.loadLetter.bind(this);
    }

    componentDidMount() {
        let letterId = window.location.pathname.split("/")[2];
        this.loadLetter(letterId);
    }

    loadLetter(letterId) {
        this.props.publicDocumentsService.getLetterById(letterId).then(
            function(response){
                let imageUrl = response.data.response ? response.data.response.imageUrl : undefined;
                this.setState({
                    letter: [response.data.response],
                    imageUrl: imageUrl,
                    isLoadingPage: false
                });
            }.bind(this),
            function(error){
                this.setState({
                    isLoadingPage: false
                });
            }.bind(this));
    }

    render() {
        return (
            <div>
                <div className="app-header">
                    <div className="app-logo-container">
                        <img src={appLogo} className="app-logo" alt="app-logo"></img>
                    </div>
                </div>
                <div className="page-loading-spinner" hidden={!this.state.isLoadingPage}>
                    <div className="page-loading-spinner-container">
                        <CircularProgress size="5rem" />
                    </div>
                </div>
                <div className="public-view-container" hidden={this.state.isLoadingPage}>
                    <div className="letter-view-import-text-container" hidden={!this.state.imageUrl}>
                        This letter was imported from an image, see the original image by clicking <a className="view-import-image-anchor" href={this.state.imageUrl}>here</a>
                    </div>
                    <SingleLetterView letter={this.state.letter}></SingleLetterView>
                </div>
            </div>
        )
    }
}
export default PublicSingleLetterViewPage;