import React from 'react';
import './../styles/UnlockLetterComponent.css';
import './../App.css';
import CircularProgress from '@mui/material/CircularProgress';
import { Navigate } from "react-router-dom";

import {userContext} from './../userContext';

class UnlockLetterComponent extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            showInvalidLetterCodeError: false,
            redirectId: undefined
        }

        this.unlockLetter = this.unlockLetter.bind(this);
    }

    componentDidMount() {
        if (this.context.documentsService) {
            this.documentsService = this.context.documentsService;
        }
    }

    unlockLetter() {
        this.setState({isLoading: true});
        let letterCode = document.getElementById("unlockLetterInput").value;
        this.documentsService.unlockLetter(letterCode).then(
            function(response){
                let letter = response.data.Item;
                this.setState({
                    isLoading: false,
                    redirectId: letter.id.S,
                    showInvalidLetterCodeError: false
                });
            }.bind(this),
            function(error){
                this.setState({
                    showInvalidLetterCodeError: true,
                    isLoading: false
                });
            }.bind(this)
        );
    }

    onLetterCodeInputHandler(event) {
        if (event && event.charCode === 13) {
            this.unlockLetter();
        }
    }

    render() {
        if (this.state.redirectId){
            let urlRedirect = "/letters/" + this.state.redirectId;
            return (
                <Navigate to={urlRedirect} />
            );
        }
        return (
            <div className="unlock-letter-component">
                <div className="modal-component-title">
                    Unlock Letter
                </div>
                <div className="unlock-letter-text">
                    Enter your secret key below and unlock your letter!
                </div>
                <input id="unlockLetterInput" className="unlock-letter-input" onKeyPress={this.onLetterCodeInputHandler.bind(this)}></input>
                <div hidden={!this.state.showInvalidLetterCodeError} className="modal-message-wrapper">
                    <div className="modal-message failure">
                        Letter not found, try again :/
                    </div>
                </div>
                <div className="modal-button-container">
                    <div className="primary-button-container">
                        <button className="primary-button" onClick={this.unlockLetter}>Submit</button>
                    </div>
                    <div className="loading-spinner-container" hidden={!this.state.isLoading}>
                        <CircularProgress />
                    </div>
                </div>
            </div>
        );
    }
}
UnlockLetterComponent.contextType = userContext;
export default UnlockLetterComponent;