import React from 'react';
import './../styles/LetterListItem.css';
import './../App.css';
import { Link } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import { Navigate } from "react-router-dom";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Tooltip from '@mui/material/Tooltip';
import MoveDocumentComponent from './MoveDocumentComponent';
import DeleteDocumentComponent from './DeleteDocumentComponent';
import LockIcon from '@mui/icons-material/Lock';

var DELETE = "DELETE";
var MOVE = "MOVE";
var SHARE = "SHARE";

class LetterListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false
        }

        this.refreshListView = this.props.refreshListView;
        this.openDeleteModal = this.openDeleteModal.bind(this);
        this.openShareModal = this.openShareModal.bind(this);
        this.openMoveModal = this.openMoveModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleDeleteDocumentConfirmation = this.handleDeleteDocumentConfirmation.bind(this);
    }

    openDeleteModal(){
        this.setState({
            modalOpen: true,
            modalView: DELETE
        });
    }

    openShareModal() {
        this.setState({
            redirectToShare: true
        });
    }

    openMoveModal() {
        this.setState({
            modalOpen: true,
            modalView: MOVE
        });
    }

    handleDeleteDocumentConfirmation() {
        this.refreshListView();
        this.closeModal();
    }

    closeModal() {
        this.setState({
            modalOpen: false
        });
    }

    getButtonContainerContent(linkPath) {
        let letterIcons = [];
        if (this.props.letter.lockStatus === "LOCKED") {
            letterIcons.push(
                <div className="list-item-icon">
                        <Tooltip title="Locked">
                            <LockIcon />
                        </Tooltip>
                </div>
            )
        }
        letterIcons.push(
            <div className="list-item-icon" onClick={this.openShareModal}>
                <Tooltip title="Share">
                    <SendIcon />
                </Tooltip>
            </div>
        )
        letterIcons.push(
            <Link to={linkPath}>
                <div className="list-item-icon">
                    <Tooltip title="Edit">
                        <EditIcon />
                    </Tooltip>
                </div>
            </Link>
        )
        letterIcons.push(
            <div className="list-item-icon" onClick={this.openDeleteModal}>
                <Tooltip title="Delete">
                    <DeleteIcon />
                </Tooltip>
            </div>
        )
        return (
            <div className="letter-list-item-icon-container">
                {letterIcons}
            </div>
        );
    }

    getModalContent() {
        let modalContent = (
            <div>
                <DeleteDocumentComponent title={this.props.letter.title} letterId={this.props.letter.id} closeComponent={this.handleDeleteDocumentConfirmation}></DeleteDocumentComponent>
            </div>
        );
        if (this.state.modalView === MOVE) {
            modalContent = (
                <div>
                    <MoveDocumentComponent></MoveDocumentComponent>
                </div>
            );
        }
        return modalContent;
    }

    render() {
        if (this.state.redirectToShare) {
            let redirectURL = "/share/" + this.props.letter.id;
            return (
                <Navigate to={redirectURL} />
            );
        }

        if (this.props.letter.type === "FOLDER") {
            return (
                <div className="letter-list-item-container folder">
                    <div className="list-item-title folder-title">{this.props.letter.title}</div>
                </div>);
        }
        let truncatedBody = this.props.letter.body.substring(0, 100);
        let letterId = this.props.letter.id;
        let linkPathToEdit = "/edit/" + letterId;
        let buttonContainerContent = [];
        if (!this.props.isReadOnly) {
            buttonContainerContent = this.getButtonContainerContent(linkPathToEdit);
        }
        let modalContent = [];
        if (!this.props.isReadOnly) {
            modalContent = this.getModalContent();
        }
        let letterClickLinkPath = "/letters/" + letterId;
        if (!this.props.isReadOnly) {
            letterClickLinkPath = linkPathToEdit;
        }
        return (
            <div className="letter-list-item-container document" onMouseLeave={this.hideIcons} style={{ backgroundImage: `url(${this.props.letter.stationaryId})` }}>
                <Link to={letterClickLinkPath}>
                    <div className="list-item-title document-title">
                        {this.props.letter.title}
                        <div className="truncated-body-text">{truncatedBody}</div>
                    </div>
                </Link>
                {buttonContainerContent}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.modalOpen}
                    onClose={this.closeModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.modalOpen}>
                        <div className="login-modal-wrapper share-modal">
                            {modalContent}
                        </div>
                    </Fade>
                </Modal>
            </div>
        );
    }
}

export default LetterListItem;
