import React from 'react';
import './../styles/ShareLetterNavigation.css';
import './../App.css';

var TYPE_VIEW = "TYPE_VIEW";
var RECEIVER_VIEW = "RECEIVER_VIEW";
var DATE_VIEW = "DATE_VIEW";
var SUMMARY_VIEW = "SUMMARY_VIEW";

class ShareLetterNavigation extends React.Component {
    render() {
        return (
            <div className="letter-share-navigation">
                <div className="letter-share-nav-container" onClick={this.props.setView.bind(this, TYPE_VIEW)}>
                    <div className="letter-share-nav-title">How To Share It</div>
                    <div id="howShareSelection" className="letter-share-nav-selection share-nav-selected"></div>
                </div>
                <div className="letter-share-nav-hr-container">
                    <hr className="letter-share-hr"></hr>
                </div>
                <div className="letter-share-nav-container" onClick={this.props.setView.bind(this, RECEIVER_VIEW)}>
                    <div className="letter-share-nav-title">Who To Send It To</div>
                    <div id="whoShareSelection" className="letter-share-nav-selection"></div>
                </div>
                <div className="letter-share-nav-hr-container">
                    <hr className="letter-share-hr"></hr>
                </div>
                <div className="letter-share-nav-container" onClick={this.props.setView.bind(this, DATE_VIEW)}>
                    <div className="letter-share-nav-title">When To Send It</div>
                    <div id="dateShareSelection" className="letter-share-nav-selection"></div>
                </div>
                <div className="letter-share-nav-hr-container">
                    <hr className="letter-share-hr"></hr>
                </div>
                <div className="letter-share-nav-container" onClick={this.props.setView.bind(this, SUMMARY_VIEW)}>
                    <div className="letter-share-nav-title">Summary</div>
                    <div id="summaryShareSelection" className="letter-share-nav-selection"></div>
                </div>
            </div>
        )
    }
}

export default ShareLetterNavigation;
