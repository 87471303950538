import React from 'react';
import './../styles/DeleteDocumentComponent.css';
import './../App.css';
import CircularProgress from '@mui/material/CircularProgress';

import {userContext} from './../userContext';

class DeleteDocumentComponent extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            showDeleteFailedMessage: false
        }

        this.deleteLetter = this.deleteLetter.bind(this);
    }

    componentDidMount() {
        if (this.context.documentsService) {
            this.documentsService = this.context.documentsService;
        }
    }

    deleteLetter() {
        this.setState({isLoading: true});
        this.documentsService.deleteLetter(this.props.letterId).then(
            function(response){
                this.setState({
                    showDeleteFailedMessage: false,
                    isLoading: false
                });
                this.props.closeComponent();
            }.bind(this),
            function(error){
                console.log(error);
                this.setState({
                    showDeleteFailedMessage: true,
                    isLoading: false
                });
            }.bind(this)
        );
    }

    render() {
        return (
            <div className="delete-document-component">
                <div className="modal-component-title">
                    Delete Letter?
                </div>
                <div className="delete-body-text">
                    Are you sure you want to delete letter <span className="delete-letter-title">{this.props.title}</span>. You will not be able to undo this action. Anyone who has received this letter will not longer be able to access it.
                </div>
                <div className="modal-button-container">
                    <div className="primary-button-container horizontal-button">
                        <button className="primary-button delete-button" onClick={this.deleteLetter}>Delete</button>
                    </div>
                    <div className="primary-button-container horizontal-button">
                        <button className="primary-button" onClick={this.props.closeComponent}>Cancel</button>
                    </div>
                    <div className="loading-spinner-container" hidden={!this.state.isLoading}>
                        <CircularProgress />
                    </div>
                </div>
                <div hidden={!this.state.showDeleteFailedMessage} className="modal-message-wrapper">
                    <div className="modal-message failure">
                        Failed to delete letter, please try again later
                    </div>
                </div>
            </div>
        );
    }
}
DeleteDocumentComponent.contextType = userContext;
export default DeleteDocumentComponent;