import React from 'react';
import './../styles/VerticalMenu.css';
import './../App.css';
import { Link } from "react-router-dom";
import EmailIcon from '@mui/icons-material/Email';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DraftsIcon from '@mui/icons-material/Drafts';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Tooltip from '@mui/material/Tooltip';

import {userContext} from './../userContext';

class VerticalMenu extends React.Component {
    render() {
        let windowPath = window.location.pathname;
        return (
            <userContext.Consumer>
                {({user, logoutUser}) => {
                    return (
                        <div className="vertical-app-menu-container">
                            <div className={windowPath.includes("/profile/letters") ? "vertical-menu-icon-menu-button vertical-menu-selected" : "vertical-menu-icon-menu-button"}>
                                <Link to="/profile/letters">
                                    <div>
                                        <Tooltip title="My Letters">
                                            <DriveFileRenameOutlineIcon sx={{ fontSize: 30 }}/>
                                        </Tooltip>
                                    </div>
                                </Link>
                            </div>
                            <div className={windowPath.includes("/profile/inbox") ? "vertical-menu-icon-menu-button vertical-menu-selected" : "vertical-menu-icon-menu-button"}>
                                <Link to="/profile/inbox">
                                    <div>
                                        <Tooltip title="Inbox">
                                            <DraftsIcon sx={{ fontSize: 30 }}/>
                                        </Tooltip>
                                    </div>
                                </Link>
                            </div>
                            <div className={windowPath.includes("/profile/scheduled-shares") ? "vertical-menu-icon-menu-button vertical-menu-selected" : "vertical-menu-icon-menu-button"}>
                                <Link to="/profile/scheduled-shares">
                                    <div>
                                        <Tooltip title="Scheduled Letters">
                                            <AccessTimeIcon sx={{ fontSize: 30 }}/>
                                        </Tooltip>
                                    </div>
                                </Link>
                            </div>
                            <div className={windowPath.includes("/profile/stationery") ? "vertical-menu-icon-menu-button vertical-menu-selected" : "vertical-menu-icon-menu-button"}>
                                <Link to="/profile/stationery">
                                    <div>
                                        <Tooltip title="Stationery">
                                            <InsertPhotoIcon sx={{ fontSize: 30 }}/>
                                        </Tooltip>
                                    </div>
                                </Link>
                            </div>
                            {/* <div className="vertical-menu-icon-menu-button">
                                <Link to="/profile/reminders">
                                    <div>
                                        <Tooltip title="Reminders">
                                            <NotificationsActiveIcon sx={{ fontSize: 30 }}/>
                                        </Tooltip>
                                    </div>
                                </Link>
                            </div>
                            <div className="vertical-menu-icon-menu-button">
                                <Link to="/profile/store">
                                    <div>
                                        <Tooltip title="Store">
                                            <StorefrontIcon sx={{ fontSize: 30 }}/>
                                        </Tooltip>
                                    </div>
                                </Link>
                            </div> */}
                            <div className="vertical-menu-icon-menu-button logout-button">
                                <div onClick={logoutUser}>
                                    <Tooltip title="Logout">
                                        <ExitToAppIcon sx={{ fontSize: 30 }}/>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </userContext.Consumer>
        );
    }
}
VerticalMenu.contextType = userContext;
export default VerticalMenu;