import React from 'react';
import './../styles/ShareLetterSummary.css';
import './../App.css';

var PUBLIC_AUTH = "PUBLIC";

class ShareLetterSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showValidationMessage: false
        }

        this.validateAndNext = this.validateAndNext.bind(this);
    }

    getFormattedDate(date) {
        var year = date.getFullYear();
      
        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
      
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        
        return month + '/' + day + '/' + year;
    }

    validateAndNext() {
        let receiverValidation = this.props.receiver || (this.props.letterAuthorization === PUBLIC_AUTH);
        if (receiverValidation && this.props.shareType && this.props.sentDate) {
            this.setState({
                showValidationMessage: false
            });
            let today = new Date();
            if (this.getFormattedDate(today) === this.getFormattedDate(this.props.sentDate)) {
                this.props.shareLetter();
            } else {
                this.props.scheduleLetterShare();
            }
        } else {
            this.setState({
                showValidationMessage: true
            });
        }
    }

    render() {
        // Need to handle the cases where they get to summary page with missing information. Link them back to the section that requires changes
        let dateString = this.props.sentDate ? this.getFormattedDate(this.props.sentDate) : undefined;
        let shareString = this.props.letterAuthorization === PUBLIC_AUTH ? "Publicly" : "Privately";
        let receiverString = this.props.receiver ? this.props.receiver : "No User Selected";
        return (
            <div className="share-menu-section">
                <div className="summary-container">
                    <div className="summart-section-container">
                        <div className="share-summary-section">
                            <span className="share-summary-label">Share Type:</span>
                            <span className="share-summary-value">{this.props.shareType}</span>
                        </div>
                        <div className="share-summary-section">
                            <span className="share-summary-label">Sending To:</span>
                            <span className="share-summary-value">{receiverString}</span>
                        </div>
                        <div className="share-summary-section">
                            <span className="share-summary-label">Available:</span>
                            <span className="share-summary-value">{shareString}</span>
                        </div>
                        <div className="share-summary-section">
                            <span className="share-summary-label">Sending On:</span>
                            <span className="share-summary-value">{dateString}</span>
                        </div>
                    </div>
                </div>
                <div className="primary-button-wrapper center-button">
                    <button className="primary-button" onClick={this.validateAndNext}>Share</button>
                    <div hidden={!this.state.showValidationMessage} className="modal-message-wrapper">
                        <div className="modal-message failure">
                            Make sure to enter all required information to share your letter, a type, a date, and either a receiver or public access
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShareLetterSummary;
