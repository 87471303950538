class DocumentsService {
    constructor(amplifyRequestService, apiName) {
      this.amplifyRequestService = amplifyRequestService;
      this.apiName = apiName;
      this.documentsCache = undefined;
      this.singleDocumentCache = {};
      this.documentsReceivedCache = undefined;
    }
  
    getAllDocuments() {
      if (this.documentsCache){
        return this.documentsCache;
      }
      this.documentsCache = this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/documents', "GET");
      return this.documentsCache;
    }

    saveDocument(document) {
      let request = {
        body: document
      }
      if (document.id){
        this.singleDocumentCache[document.id] = undefined;
      }
      return this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/documents', "POST", request);
    }

    getDocumentById(id) {
      if (this.singleDocumentCache[id]){
        return this.singleDocumentCache[id];
      }
      let queryParams = {
        queryStringParameters: {
          documentId: [id]
        }
      }
      this.singleDocumentCache[id] = this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/documents', "GET", queryParams);
      return this.singleDocumentCache[id];
    }

    getDocumentsReceived(email){
      if (!this.documentsReceivedCache) {
        let queryParams = {
          queryStringParameters: {
            email: email
          }
        }
        this.documentsReceivedCache = this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/documents', "GET", queryParams);
      }
      return this.documentsReceivedCache;
    }

    deleteLetter(id) {
      let queryParams = {
        queryStringParameters: {
          documentId: [id]
        }
      }
      // clean out cache upon delete
      this.singleDocumentCache[id] = undefined;
      this.documentsCache = undefined;
      return this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/documents', "DELETE", queryParams);
    }

    shareLetter(documentId, authorization, shareType, receiverEmail, notifyReceiver) {
      let request = {
        body: {
          documentId,
          authorization,
          shareType,
          receiverEmail,
          notifyReceiver
        }
      }
      return this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/share', "POST", request);
    }

    getStationary() {
      return this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/stationaries', "GET");
    }

    unlockLetter(letterCode) {
      let queryParams = {
        queryStringParameters: {
          letterCode: [letterCode]
        }
      }
      return this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/unlock-letter', "POST", queryParams);
    }

    importLetter(letterTitle, letterFileTitle, letterBase64) {
      let params = {
        queryStringParameters: {
          letterTitle,
          letterFileTitle
        },
        body: {
          letterBase64
        }
      }
      return this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/letters', "PUT", params);
    }
  
  }
  
  export default DocumentsService;