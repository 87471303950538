import React from 'react';
import './../styles/AppHeader.css';
import './../App.css';
import appLogo from "../img/logos/canva-logo.png";
import { Navigate, Link } from "react-router-dom";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import Tooltip from '@mui/material/Tooltip';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import UnlockLetterComponent from './UnlockLetterComponent';

import {userContext} from './../userContext';
import ImportLetterComponent from './ImportLetterComponent';

const UNLOCK_LETTER_VIEW = "UNLOCK_LETTER_VIEW";
const IMPORT_LETTER_VIEW = "IMPORT_LETTER_VIEW";

class AppHeader extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            headerModalOpen: false,
        }

        this.openHeaderModal = this.openHeaderModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openHeaderModal(view, context) {
        this.setState({
            headerModalOpen: true,
            view: view
        });
    }

    closeModal() {
        this.setState({
            headerModalOpen: false
        });
    }

    render() {
        if (this.context.user.length === 0){
            return (
                <Navigate to="/" />
            );
        }
        return (
            <userContext.Consumer>
                {({user}) => {
                    return (
                        <div className="app-header">
                            <div className="app-logo-container">
                                <img src={appLogo} className="app-logo" alt="app-logo"></img>
                            </div>
                            <div className="horizontal-menu-icon-button secondary-icon-button flex-align-right" onClick={this.openHeaderModal.bind(this, UNLOCK_LETTER_VIEW)}>
                                <div>
                                    <Tooltip title="Unlock a Letter">
                                        <LockOpenIcon sx={{ fontSize: 30 }}/>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="horizontal-menu-icon-button secondary-icon-button" onClick={this.openHeaderModal.bind(this, IMPORT_LETTER_VIEW)}>
                                <div>
                                    <Tooltip title="Import a Letter">
                                        <UploadFileIcon sx={{ fontSize: 30 }}/>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="horizontal-menu-icon-button" onClick={this.props.resetLetterEditor}>
                                <Link to="/profile/stationery">
                                    <div>
                                        <Tooltip title="Create New Letter">
                                            <NoteAddIcon sx={{ fontSize: 30 }}/>
                                        </Tooltip>
                                    </div>
                                </Link>
                            </div>
                            <div className="account-container">
                                <Link to="/account">
                                    <div className="profile-avatar">
                                        {user[0][0].idToken.payload["cognito:username"]}
                                    </div>
                                </Link>
                            </div>
                            <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={this.state.headerModalOpen}
                                onClose={this.closeModal}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}
                            >
                                <Fade in={this.state.headerModalOpen}>
                                    <div className="login-modal-wrapper">
                                        <div hidden={this.state.view !== UNLOCK_LETTER_VIEW}>
                                            <UnlockLetterComponent></UnlockLetterComponent>
                                        </div>
                                        <div hidden={this.state.view !== IMPORT_LETTER_VIEW}>
                                            <ImportLetterComponent></ImportLetterComponent>
                                        </div>
                                    </div>
                                </Fade>
                            </Modal>
                        </div>
                    );
                }}
            </userContext.Consumer>
        );
    }
}
AppHeader.contextType = userContext;
export default AppHeader;