// This project uses amplify to perform basic authentication but no authorization currently with the following configuration.
// https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#account-recovery-verification

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_EPB1LcOrg",
    "aws_user_pools_web_client_id": "8hh5scboctlp0b6ou85pjkj7g",
    "oauth": {},
    API: {
        endpoints: [
            {
                name: "SecureBackend",
                endpoint: "https://72fzus2i23.execute-api.us-east-1.amazonaws.com/prod"
            },
            {
                name: "PublicBackend",
                endpoint: "https://yl6kdeproc.execute-api.us-east-1.amazonaws.com/prod"
            }
        ]
    }
};

export default awsmobile;