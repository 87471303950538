import React from 'react';
import './../styles/HomePageHeader.css';
import './../App.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import appLogo from "../img/logos/canva-logo.png";
import { Auth } from 'aws-amplify';
import { Navigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';

import {userContext} from './../userContext';

var SIGN_IN = "SIGN_IN";
var CREATE_ACCOUNT = "CREATE_ACCOUNT";
var FORGOT_PASSWORD = "FORGOT_PASSWORD";
var RESET_PASSWORD = "RESET_PASSWORD";
var CODE_VERIFICATION_FLOW = "CODE_VERIFICATION_FLOW";

class HomePageHeader extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            loginModalOpen: this.props.loginModalOpen,
            userDetails: [],
            validEmailForSignup: true,
            showLoginSuccessMessage: false,
            showLoginFailureMessage: false,
            signinModalView: SIGN_IN,
            loginFailureMessage: "",
            loginSuccessMessage: "",
            isSigninLoading: false
        }

        this.signup = this.signup.bind(this);
        this.verifySignup = this.verifySignup.bind(this);
        this.toggleLoginModal = this.toggleLoginModal.bind(this);
        this.openLoginModal = this.openLoginModal.bind(this);
        this.closeLoginModal = this.closeLoginModal.bind(this);
        this.showSignIn = this.showSignIn.bind(this);
        this.showSignUp = this.showSignUp.bind(this);
        this.validateSignupEmail = this.validateSignupEmail.bind(this);
        this.validatePasswords = this.validatePasswords.bind(this);
        this.createValidationMap = this.createValidationMap.bind(this);
        this.showForgotPassword = this.showForgotPassword.bind(this);
        this.sendVerificationCode = this.sendVerificationCode.bind(this);
        this.saveForgotPassword = this.saveForgotPassword.bind(this);
        this.checkForEnter = this.checkForEnter.bind(this);
    }

    async signup(){
        let isValidPasswords = this.isValidPasswords();
        if (isValidPasswords) {
            let email = document.getElementById("emailInput").value;
            let nameReplace = email.replace(/@.*$/,"");
            let username = nameReplace!==email ? nameReplace : null;
            let password = document.getElementById("passwordInputPrimary").value;
            try {
                await Auth.signUp({
                    username,
                    password,
                    attributes: {
                        email,
                    },
                    autoSignIn: {
                        enabled: true,
                    }
                });
                this.setState({signinModalView: CODE_VERIFICATION_FLOW});
                this.clearInputs();
            } catch (error) {
                let errorMessage = "An unknown problem occurred, please try again later";
                if (error.toString().indexOf("UsernameExistsException") > -1) {
                    errorMessage = "Looks like you already have an account, signin or reset your password!";
                }
                this.setState(
                    {
                        showLoginFailureMessage: true,
                        loginFailureMessage: errorMessage
                    }
                );
            }
        }
    }

    async verifySignup(){
        let email = document.getElementById("emailInput").value;
        let nameReplace = email.replace(/@.*$/,"");
        let username = nameReplace!==email ? nameReplace : null;
        let code = document.getElementById("verificationCodeInput").value;
        try {
            await Auth.confirmSignUp(username, code);
            this.setState({
                showLoginSuccessMessage: true,
                loginSuccessMessage: "You have successfully registered your account, now signin to enter!"
            });
            this.showSignIn();
        } catch (error) {
            let errorMessage = "Something went wrong, please try again";
            if (error.toString().indexOf("CodeMismatchException") > -1) {
                errorMessage = "Invalid verification code, double check you're using the correct one";
            }
            this.setState({
                showLoginFailureMessage: true,
                loginFailureMessage: errorMessage
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log(this.props.loginModalOpen);
        console.log(prevProps);
        if (this.context.user.length > 0 && this.state.userDetails.length === 0) {
            // user has signed in, set the user and then close login modal
            this.setState({
                loginModalOpen: false,
                userDetails: this.context.user
            });
        }
        if (this.props.loginModalOpen !== prevProps.loginModalOpen) {
            this.setState({
                loginModalOpen: true
            });
        }
    }

    toggleLoginModal() {
        this.setState({loginModalOpen: !this.state.loginModalOpen})
    }

    openLoginModal(event, context) {
        let view = event ? SIGN_IN : CREATE_ACCOUNT;
        this.setState({
            loginModalOpen: true,
            signinModalView: view,
            showLoginFailureMessage: false,
            loginFailureMessage: "",
            showLoginSuccessMessage: false,
            loginSuccessMessage: "",
        });
        this.clearInputs();
    }

    closeLoginModal() {
        this.setState({loginModalOpen: false});
    }

    showSignIn() {
        if (this.state.signinModalView !== SIGN_IN) {
            document.getElementById("signinDiv").classList.add("sign-in-selected");
            document.getElementById("signupDiv").classList.remove("sign-in-selected");
            this.setState({
                signinModalView: SIGN_IN,
                showLoginFailureMessage: false,
                loginFailureMessage: ""
            });
        }
    }

    showSignUp() {
        if (this.state.signinModalView !== CREATE_ACCOUNT) {
            document.getElementById("signinDiv").classList.remove("sign-in-selected");
            document.getElementById("signupDiv").classList.add("sign-in-selected");
            this.setState({
                signinModalView: CREATE_ACCOUNT,
                showLoginFailureMessage: false,
                loginFailureMessage: ""
            });
        }
    }

    showForgotPassword() {
        this.setState({
            signinModalView: FORGOT_PASSWORD,
            showLoginFailureMessage: false,
            loginFailureMessage: ""
        });
    }

    async saveForgotPassword() {
        let username = document.getElementById("emailInput").value;
        let verificationCode = document.getElementById("verificationCodeInput").value;
        let password = document.getElementById("passwordInputPrimary").value;
        let isValidPasswords = this.isValidPasswords();
        if (isValidPasswords) {
            Auth.forgotPasswordSubmit(username, verificationCode, password)
                .then(function(data){
                    this.setState({
                        showLoginSuccessMessage: true,
                        loginSuccessMessage: "You have successfully reset your password, now signin to enter!"
                    });
                    this.clearInputs();
                    this.showSignIn();
                }.bind(this))
                .catch(function(err){
                    let errorMessage = "Something went wrong, please try again";
                    if (err.toString().indexOf("CodeMismatchException") > -1) {
                        errorMessage = "Invalid verification code, double check you're using the correct one";
                    }
                    this.setState({
                        showLoginFailureMessage: true,
                        loginFailureMessage: errorMessage
                    });
                }.bind(this));
        } else {
            this.setState({
                showLoginFailureMessage: true,
                loginFailureMessage: "Invalid passwords, make sure you are meeting the criteria below"
            });
        }
    }

    async sendVerificationCode() {
        let username = document.getElementById("emailInput").value;
        if (username) {
            if (this.isValidEmail(username)){
                Auth.forgotPassword(username)
                    .then(function(data){
                        this.setState({
                            signinModalView: RESET_PASSWORD,
                            showLoginFailureMessage: false,
                            loginFailureMessage: ""
                        });
                    }.bind(this))
                    .catch(function(err){
                        this.setState({
                            showLoginFailureMessage: true,
                            loginFailureMessage: "Something went wrong, please try again"
                        });
                    }.bind(this));
            } else {
                this.setState(
                    {
                        showLoginFailureMessage: true,
                        loginFailureMessage: "Please provide a valid email address"
                    }
                );
            }
        }
    }

    clearInputs() {
        let passwordInputPrimary = document.getElementById("passwordInputPrimary");
        let passwordInputSecondary = document.getElementById("passwordInputSecondary");
        let verificationCodeInput = document.getElementById("verificationCodeInput");
        if (passwordInputPrimary) {
            passwordInputPrimary.value = "";
        }
        if (passwordInputSecondary) {
            passwordInputSecondary.value = "";
        }
        if (verificationCodeInput) {
            verificationCodeInput.value = "";
        }
    }

    checkForEnter(event) {
        if (event && event.code && event.code === "Enter") {
            switch(this.state.signinModalView){
                case SIGN_IN:
                    document.getElementById("loginButton").click();
                    break;
                case CREATE_ACCOUNT:
                    document.getElementById("createAccountButton").click();
                    break;
                case FORGOT_PASSWORD:
                    document.getElementById("sendVerificationCodeButton").click();
                    break;
                case RESET_PASSWORD:
                    document.getElementById("saveNewPasswordButton").click();
                    break;
                case CODE_VERIFICATION_FLOW:
                    document.getElementById("verifyAccountButton").click();
                    break;
                default:
                    break;
            }
        }
    }

    isValidEmail(email) {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    isValidPasswords() {
        let validationMap = this.createValidationMap();
        for (let i in validationMap) {
            if (!validationMap[i].valid) {
                return false;
            }
        }
        return true;
    }

    validateSignupEmail() {
        let email = document.getElementById("emailInput").value;
        if (email) {
            let isValidEmail = this.isValidEmail(email);
            this.setState({validEmailForSignup:(isValidEmail) ? true : false});
        }
    }

    validatePasswords() {
        let validationMap = this.createValidationMap();
        for (let i in validationMap) {
            let entry = validationMap[i];
            let element = document.getElementById(entry.elementId);
            if (entry.valid) {
                if (element.classList.contains("criteria-not-met")){
                    document.getElementById(entry.elementId).classList.remove("criteria-not-met");
                }
            } else {
                if (!element.classList.contains("criteria-not-met")) {
                    document.getElementById(entry.elementId).classList.add("criteria-not-met");
                }
            }
        }
    }

    createValidationMap() {
        let password = document.getElementById("passwordInputPrimary").value;
        let confirmationPassword = document.getElementById("passwordInputSecondary").value;
        return {
            length: {
                valid: password.length >= 8,
                elementId: "passwordLengthCriteria"
            },
            upperCase: {
                valid: /[A-Z]/.test(password),
                elementId: "upperCaseCharacterCriteria"
            },
            lowerCase: {
                valid: /[a-z]/.test(password),
                elementId: "lowerCaseCharacterCriteria"
            },
            specialCharacters: {
                valid: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password),
                elementId: "specialCharacterCriteria"
            },
            passwordMatch: {
                valid: password === confirmationPassword,
                elementId: "passwordMatchingCriteria"
            },
            numberMatch: {
                valid: /[0-9]/.test(password),
                elementId: "numberCriteria"
            }
        }
    }

    render() {
        if (this.context.user.length > 0) {
            if (this.context.redirectPath && this.context.redirectPath !== "/") {
                return (
                    <Navigate to={this.context.redirectPath} />
                );
            }
            return (
                <Navigate to="/profile" />
            );
        }
        return (
            <div className="home-page-header">
                <div className="app-logo-container">
                    <img src={appLogo} className="home-page-app-logo" alt="app-logo"></img>
                </div>
                <div>
                    <div className="menu-button-container">
                        <button className="menu-button"><a href="#top">Home</a></button>
                    </div>
                    <div className="menu-button-container">
                        <button className="menu-button"><a href="#howItWorksSection">How it Works</a></button>
                    </div>
                    <div className="menu-button-container">
                        <button className="menu-button"><a href="mailto:support@writenow.social">Contact Us</a></button>
                    </div>
                </div>
                <div className="sign-in-buttons-header-container">
                    <div className="menu-button-container">
                        <button className="menu-button" onClick={this.openLoginModal.bind(this, SIGN_IN)}>Sign In</button>
                    </div>
                    <div className="primary-button-wrapper header-button">
                        <button className="primary-button" onClick={this.openLoginModal.bind(this, CREATE_ACCOUNT)}>Sign Up</button>
                    </div>
                </div>
                <userContext.Consumer>
                    {({user, logoutUser, loginUser, isSigninLoading, signinFailed}) => {
                        return (
                            <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={this.state.loginModalOpen}
                                onClose={this.closeLoginModal}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}
                            >
                                <Fade in={this.state.loginModalOpen}>
                                    <div className="login-modal-wrapper">
                                        <div className="login-modal-header">
                                            <div id="signinDiv" className="sign-in-header sign-in-selected" onClick={this.showSignIn}>
                                                Sign In
                                            </div>
                                            <div id="signupDiv" className="sign-in-header sign-up-header" onClick={this.showSignUp}>
                                                Create an Account
                                            </div>
                                        </div>
                                        <div className="signin-wrapper">
                                            <div hidden={!this.state.showLoginSuccessMessage}>
                                                <div className="login-message success">
                                                    {this.state.loginSuccessMessage}
                                                </div>
                                            </div>
                                            <div hidden={!this.state.showLoginFailureMessage}>
                                                <div className="login-message failure">
                                                    {this.state.loginFailureMessage}
                                                </div>
                                            </div>
                                            <div hidden={!signinFailed}>
                                                <div className="login-message failure">
                                                    Invalid username or password, please try again!
                                                </div>
                                            </div>
                                            {/* Inputs */}
                                            <div className="input-container" hidden={([CODE_VERIFICATION_FLOW].includes(this.state.signinModalView))}>
                                                <input className="login-input" id="emailInput" type="text" placeholder="Email" onBlur={this.validateSignupEmail} onKeyPress={this.checkForEnter.bind(this)}></input>
                                                <div className="criteria-not-met" hidden={this.state.validEmailForSignup}>Invalid, please use a valid email address, e.g. myname@gmail.com</div>
                                            </div>
                                            <div hidden={([SIGN_IN, CREATE_ACCOUNT, FORGOT_PASSWORD].includes(this.state.signinModalView))}>
                                                You should receive an email verification code, please enter it below to confirm your address
                                            </div>
                                            <div className="input-container" hidden={([SIGN_IN, CREATE_ACCOUNT, FORGOT_PASSWORD].includes(this.state.signinModalView))}>
                                                <input className="login-input" id="verificationCodeInput" type="text" placeholder="Verification Code" onKeyPress={this.checkForEnter.bind(this)}></input>
                                            </div>
                                            <div className="input-container" hidden={([FORGOT_PASSWORD, CODE_VERIFICATION_FLOW].includes(this.state.signinModalView))}>
                                                <input className="login-input" id="passwordInputPrimary" type="password" placeholder="Password" onKeyUp={this.validatePasswords} onKeyPress={this.checkForEnter.bind(this)}></input>
                                            </div>
                                            <div className="input-container" hidden={([SIGN_IN, FORGOT_PASSWORD, CODE_VERIFICATION_FLOW].includes(this.state.signinModalView))}>
                                                <input className="login-input" id="passwordInputSecondary" type="password" placeholder="Confirm New Password" onKeyUp={this.validatePasswords} onKeyPress={this.checkForEnter.bind(this)}></input>
                                            </div>
                                            {/* Buttons */}
                                            <div className="primary-button-wrapper" hidden={!(this.state.signinModalView === SIGN_IN)}>
                                                <button id="loginButton" className="primary-button" onClick={loginUser}>Sign In</button>
                                            </div>
                                            <div className="primary-button-wrapper" hidden={([SIGN_IN, CREATE_ACCOUNT, RESET_PASSWORD, CODE_VERIFICATION_FLOW].includes(this.state.signinModalView))}>
                                                <button id="sendVerificationCodeButton" className="primary-button" onClick={this.sendVerificationCode}>Send Verification Code</button>
                                            </div>
                                            <div className="primary-button-wrapper" hidden={([SIGN_IN, CREATE_ACCOUNT, FORGOT_PASSWORD, CODE_VERIFICATION_FLOW].includes(this.state.signinModalView))}>
                                                <button id="saveNewPasswordButton" className="primary-button" onClick={this.saveForgotPassword}>Save New Password</button>
                                            </div>
                                            <div className="primary-button-wrapper" hidden={!(this.state.signinModalView === CREATE_ACCOUNT)}>
                                                <button id="createAccountButton" className="primary-button" onClick={this.signup}>Sign Up</button>
                                            </div>
                                            <div className="primary-button-wrapper" hidden={([SIGN_IN, CREATE_ACCOUNT, FORGOT_PASSWORD, RESET_PASSWORD].includes(this.state.signinModalView))}>
                                                <button id="verifyAccountButton" className="primary-button" onClick={this.verifySignup}>Verify Account</button>
                                            </div>
                                            <div className="loading-spinner-container" hidden={!(this.state.isSigninLoading || isSigninLoading)}>
                                                <CircularProgress />
                                            </div>
                                            <div className="forget-password-text" hidden={!(this.state.signinModalView === SIGN_IN)}>
                                                Forgot your password? Click <span className="forget-password-text-link" onClick={this.showForgotPassword}>here</span>
                                            </div>
                                            <div className="password-requirements-wrapper" hidden={([SIGN_IN, FORGOT_PASSWORD, CODE_VERIFICATION_FLOW].includes(this.state.signinModalView))}>
                                                <div className="password-requirements-container">
                                                    <div className="password-requirements-header">Password Requirements</div>
                                                    <div id="passwordLengthCriteria" className="password-requirement-checklist criteria-not-met">At least 8 characters</div>
                                                    <div id="upperCaseCharacterCriteria" className="password-requirement-checklist criteria-not-met">At least 1 upper case character</div>
                                                    <div id="lowerCaseCharacterCriteria" className="password-requirement-checklist criteria-not-met">At least 1 lower case character</div>
                                                    <div id="numberCriteria" className="password-requirement-checklist criteria-not-met">At least 1 number</div>
                                                    <div id="specialCharacterCriteria" className="password-requirement-checklist criteria-not-met">At least 1 special character</div>
                                                    <div id="passwordMatchingCriteria" className="password-requirement-checklist criteria-not-met">Passwords matching</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </Modal>
                        );
                    }}
                </userContext.Consumer>
            </div>
        );
    }
}
HomePageHeader.contextType = userContext;
export default HomePageHeader;