import React from 'react';
import './../styles/ImportLetterComponent.css';
import './../App.css';
import CircularProgress from '@mui/material/CircularProgress';
import { Navigate } from "react-router-dom";

import {userContext} from './../userContext';

class ImportLetterComponent extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            showErrorMessage: false,
            redirectId: undefined,
            fileToUpload: undefined,
            missingRequiredParams: false
        }

        this.importLetter = this.importLetter.bind(this);
    }

    componentDidMount() {
        if (this.context.documentsService) {
            this.documentsService = this.context.documentsService;
        }
    }

    importLetter() {
        let letterTitle = document.getElementById("letterTitleInput").value;
        let letterFileTitle = document.getElementById("fileInput").value;
        if (letterTitle && letterFileTitle) {
            this.setState({isLoading: true});
            let fileBase64 = '';
            this.getBase64(this.state.fileToUpload, function(result){
                fileBase64 = result;
                this.documentsService.importLetter(letterTitle, letterFileTitle, fileBase64).then(
                    function(response){
                        let letter = response.data.Item;
                        this.setState({
                            isLoading: false,
                            redirectId: letter.id.S,
                            showErrorMessage: false,
                            missingRequiredParams: false
                        });
                    }.bind(this),
                    function(error){
                        this.setState({
                            showErrorMessage: true,
                            isLoading: false,
                            missingRequiredParams: false
                        });
                    }.bind(this)
                );
            }.bind(this));
        } else {
            this.setState({
                missingRequiredParams: true
            });
        }
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    render() {
        if (this.state.redirectId){
            let urlRedirect = "/edit/" + this.state.redirectId;
            return (
                <Navigate to={urlRedirect} />
            );
        }
        return (
            <div className="import-letter-component">
                <div className="modal-component-title">
                    Import a Letter
                </div>
                <div className="modal-body-container">
                    <div className="modal-component-sub-text">
                        Add a photo of your letter, and we'll do our best to read it and create a letter for you. You will always be able to see the original image and you can also edit anything we get wrong.
                    </div>
                    <div className="input-container">
                        <input className="login-input" id="letterTitleInput" type="text" placeholder="Enter Letter Title"></input>
                    </div>
                    <div className="primary-input-container">
                        <input id="fileInput" type="file" onChange={e => {
                                    this.setState({
                                        fileToUpload: e.target.files[0]
                                    });
                                }}
                        ></input>
                    </div>
                </div>
                <div hidden={!this.state.showErrorMessage} className="modal-message-wrapper">
                    <div className="modal-message failure">
                        Importing your letter failed, please try again later :/
                    </div>
                </div>
                <div hidden={!this.state.missingRequiredParams} className="modal-message-wrapper">
                    <div className="modal-message failure">
                        Title and a valid file are required, please input both
                    </div>
                </div>
                <div className="modal-button-container">
                    <div className="primary-button-container">
                        <button className="primary-button" onClick={this.importLetter}>Import</button>
                    </div>
                    <div className="loading-spinner-container" hidden={!this.state.isLoading}>
                        <div className="loading-text">This process can take some time, hold tight...</div>
                        <CircularProgress />
                    </div>
                </div>
            </div>
        );
    }
}
ImportLetterComponent.contextType = userContext;
export default ImportLetterComponent;