import React from 'react';
import './../styles/PrivateSingleLetterViewPage.css';
import './../App.css';
import AppHeader from './../components/AppHeader';
import VerticalMenu from '../components/VerticalMenu';
import SingleLetterView from '../components/SingleLetterVIew';
import CircularProgress from '@mui/material/CircularProgress';

import {userContext} from './../userContext';

class PrivateSingleLetterViewPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            letter: [],
            isLoadingPage: true
        }
    }

    componentDidMount() {
        this.documentsService = this.context.documentsService;
        let pathParams = window.location.pathname.split("/");
        let letterId = undefined;
        if (pathParams.length > 2) {
            letterId = pathParams[2];
        }
        if (this.documentsService && letterId) {
            this.documentsService.getDocumentById(letterId).then(
                function(response){
                    let letter = response.data.response;
                    let imageUrl = letter ? letter.imageUrl : undefined
                    this.setState({
                        letter: [letter],
                        imageUrl: imageUrl,
                        isLoadingPage: false
                    });
                }.bind(this),
                function(error){
                    console.log(error);
                    this.setState({
                        isLoadingPage: false
                    });
                }.bind(this));
        }
    }

    render() {
        return (
            <div>
                <AppHeader></AppHeader>
                <div className="letter-view-container">
                    <VerticalMenu></VerticalMenu>
                    <div className="page-loading-spinner" hidden={!this.state.isLoadingPage}>
                        <div className="page-loading-spinner-container">
                            <CircularProgress size="5rem" />
                        </div>
                    </div>
                    <div className="single-letter-view-container" hidden={this.state.isLoadingPage}>
                        <div className="letter-view-import-text-container" hidden={!this.state.imageUrl}>
                            This letter was imported from an image, see the original image by clicking <a className="view-import-image-anchor" href={this.state.imageUrl}>here</a>
                        </div>
                        <SingleLetterView letter={this.state.letter}></SingleLetterView>
                    </div>
                </div>
            </div>
        )
    }
}

PrivateSingleLetterViewPage.contextType = userContext;
export default PrivateSingleLetterViewPage;