import React from 'react';
import './../styles/PublicHomePage.css';
import './../App.css';
import HomePageHeader from './../components/HomePageHeader';
import WelcomeView from './../components/WelcomeView';
import HowItWorksView from './../components/HowItWorksView';

class PublicHomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginModalOpen: "",
            counter: 0
        }

        this.openLoginModal = this.openLoginModal.bind(this);
    }

    openLoginModal() {
        this.setState({
            loginModalOpen: "open-" + this.state.counter,
            counter: this.state.counter + 1
        });
    }

    render() {
        return (
            <div>
                <HomePageHeader loginModalOpen={this.state.loginModalOpen}></HomePageHeader>
                <WelcomeView openLoginModal={this.openLoginModal}></WelcomeView>
                <HowItWorksView></HowItWorksView>
            </div>
        )
    }
}

export default PublicHomePage;