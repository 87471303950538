import React from 'react';
import './../styles/StationaryBody.css';
import './../App.css';
import CircularProgress from '@mui/material/CircularProgress';
import StationaryItem from './../components/StationaryItem';
import { Navigate } from "react-router-dom";

import {userContext} from './../userContext';

class StationaryBody extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isLoadingPage: true,
            stationaryListItems: []
        }

        this.stationaryBaseUrl = "https://stationary-bucket.s3.us-east-1.amazonaws.com/stationary/";
        this.redirectToNewLetter = this.redirectToNewLetter.bind(this);
        this.loadStationary = this.loadStationary.bind(this);
    }

    componentDidMount() {
        if (this.context.documentsService) {
            this.documentsService = this.context.documentsService;
            this.loadStationary();
        }
    }

    loadStationary() {
        this.documentsService.getStationary().then(
            function(response){
                let stationary = response.data;
                let stationaryItems = [];
                for (let i in stationary) {
                    let fullPath = this.stationaryBaseUrl + stationary[i].stationaryKey;
                    let key = "stationary-list-item-" + fullPath;
                    stationaryItems.push(
                        <StationaryItem
                            key={key}
                            stationaryId={fullPath}
                            setDocumentId={this.redirectToNewLetter}
                        >
                        </StationaryItem>
                    )
                }
                this.setState({
                    isLoadingPage: false,
                    stationaryListItems: stationaryItems
                });
            }.bind(this),
            function(error){
                console.log(error);
                this.setState({
                    isLoadingPage: false
                });
            }
        );
    }

    redirectToNewLetter(id) {
        this.setState({
            redirectId: id
        });
    }

    render() {
        if (this.state.redirectId) {
            let redirectURL = "/edit/" + this.state.redirectId;
            return (
                <Navigate to={redirectURL} />
            );
        }
        return (
            <div className="stationary-body-container">
                <div hidden={this.state.isLoadingPage}>
                    <div className="page-subheader">Pick a Stationery to Get Started</div>
                    {this.state.stationaryListItems}
                </div>
                <div className="page-loading-spinner" hidden={!this.state.isLoadingPage}>
                    <div className="page-loading-spinner-container">
                        <CircularProgress size="5rem" />
                    </div>
                </div>
            </div>
        );
    }
}
StationaryBody.contextType = userContext;
export default StationaryBody;