import React from 'react';
import './../styles/LetterDisplayView.css';
import './../App.css';
import LetterListItem from '../components/LetterListItem';

class LetterDisplayView extends React.Component {
    render() {
        let isReadOnly = this.props.isReadOnly ? true : false;
        let letterObjects = [];
        
        for (let i in this.props.letters) {
            let letter = this.props.letters[i];
            let key = "letter-list-item-" + letter.id;
            let createdDate = new Date(letter.createdTimestamp).toLocaleDateString('en-US');
            let sentString = "Draft";
            if (letter.sentTimestamp > 0){
                sentString = "Sent on " + new Date(letter.sentTimestamp).toLocaleDateString('en-US');
            }
            letterObjects.push(
                <div key={key} className="letter-view-item-container">
                    <LetterListItem isReadOnly={isReadOnly} letter={letter} refreshListView={this.props.refreshListView}></LetterListItem>
                    <div className="additional-detail-container">
                        <div>
                            {sentString}
                        </div>
                        <div hidden={isReadOnly}>
                            Created on {createdDate}
                        </div>
                    </div>
                </div>
            )
        }
        return (<div className="letter-display-view-container">
                    {letterObjects}
                </div>);
    }
}

export default LetterDisplayView;
