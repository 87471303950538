class ScheduledUpdatesService {
    constructor(amplifyRequestService, apiName) {
      this.amplifyRequestService = amplifyRequestService;
      this.apiName = apiName;
      this.scheduledUpdateCache = undefined;
    }

    getScheduledUpdates() {
      if (!this.scheduledUpdateCache) {
        this.scheduledUpdateCache = this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/scheduled-updates', "GET");
      }
      return this.scheduledUpdateCache;
    }

    saveScheduledUpdate(scheduledUpdate) {
        let request = {
            body: scheduledUpdate
        }
        this.scheduledUpdateCache = undefined;
        return this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/scheduled-updates', "PUT", request);
    }

    deleteScheduledUpdate(id) {
        let queryParams = {
          queryStringParameters: {
            documentId: [id]
          }
        }
        this.scheduledUpdateCache = undefined;
        return this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/scheduled-updates', "DELETE", queryParams);
    }
}
  
export default ScheduledUpdatesService;