import React from 'react';
import './../styles/MoveDocumentComponent.css';
import './../App.css';

import {userContext} from './../userContext';

class MoveDocumentComponent extends React.Component {
    constructor(props){
        super(props);
    }

    componentDidMount() {
    }

    componentDidUpdate() {
    }

    render() {
        return (
            <userContext.Consumer>
                {({user, logoutUser, loginUser}) => {
                    return (
                        <div className="move-document-component">
                            Hello Move Document Component
                        </div>
                    );
                }}
            </userContext.Consumer>
        );
    }
}
MoveDocumentComponent.contextType = userContext;
export default MoveDocumentComponent;