import React from 'react';
import './../styles/InboxPage.css';
import './../App.css';
import AppHeader from './../components/AppHeader';
import LetterDisplayView from '../components/LetterDisplayView';
import VerticalMenu from '../components/VerticalMenu';
import CircularProgress from '@mui/material/CircularProgress';

import {userContext} from './../userContext';

var CREATED_DATE = "CREATED_DATE";
var TITLE = "TITLE";
var SENT_DATE = "SENT_DATE";

class InboxPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            letters: [],
            sortByProperty: CREATED_DATE,
            isLoadingPage: true
        }

        this.loadLetters = this.loadLetters.bind(this);
        this.sortByCreatedDate = this.sortByCreatedDate.bind(this);
        this.sortByTitle = this.sortByTitle.bind(this);
        this.sortBySentDate = this.sortBySentDate.bind(this);
    }

    componentDidMount() {
        this.loadLetters();
    }

    loadLetters() {
        if (this.context.documentsService) {
            this.context.documentsService.getDocumentsReceived(this.context.user[0][0].idToken.payload.email).then(function(response){
                this.setState({
                    letters: response.data.response,
                    isLoadingPage: false
                });
            }.bind(this), function(error){
                console.log(error);
            }.bind(this));
        }
    }

    sortByCreatedDate() {
        if (this.state.sortByProperty !== CREATED_DATE) {
            let letters = this.state.letters.sort(function(a, b){
                return parseInt(a.createdTimestamp) - parseInt(b.createdTimestamp);
            });
            this.setSelectedSort(CREATED_DATE);
            this.setState({
                letters: letters,
                sortByProperty: CREATED_DATE
            });
        }
    }

    sortByTitle() {
        if (this.state.sortByProperty !== TITLE) {
            let letters = this.state.letters.sort(function(a, b){
                if (a.title < b.title) {
                    return -1;
                }
                if (a.title > b.title) {
                    return 1;
                }
                return 0;
            });
            this.setSelectedSort(TITLE);
            this.setState({
                letters: letters,
                sortByProperty: TITLE
            });
        }
    }

    sortBySentDate() {
        if (this.state.sortByProperty !== SENT_DATE) {
            let letters = this.state.letters.sort(function(a, b){
                return parseInt(a.sentTimestamp) - parseInt(b.sentTimestamp);
            });
            this.setSelectedSort(SENT_DATE);
            this.setState({
                letters: letters,
                sortByProperty: SENT_DATE
            });
        }
    }

    setSelectedSort(sort) {
        if (sort === CREATED_DATE) {
            document.getElementById("createdDateSortByInbox").classList.add("sort-selected");
            document.getElementById("titleSortByInbox").classList.remove("sort-selected");
            document.getElementById("sentDateSortByInbox").classList.remove("sort-selected");
        } else if (sort === TITLE) {
            document.getElementById("createdDateSortByInbox").classList.remove("sort-selected");
            document.getElementById("titleSortByInbox").classList.add("sort-selected");
            document.getElementById("sentDateSortByInbox").classList.remove("sort-selected");
        } else if (sort === SENT_DATE) {
            document.getElementById("createdDateSortByInbox").classList.remove("sort-selected");
            document.getElementById("titleSortByInbox").classList.remove("sort-selected");
            document.getElementById("sentDateSortByInbox").classList.add("sort-selected");
        }
    }

    render() {
        let webBody = (
            <div className="view-container">
                <div className="letter-view-header">
                    <div>
                        <div className="sort-by-text sort-by-header">Sort By: </div>
                        <div id="createdDateSortByInbox" className="sort-by-text sort-by-option sort-selected" onClick={this.sortByCreatedDate}>Created Date</div>
                        <div id="titleSortByInbox" className="sort-by-text sort-by-option" onClick={this.sortByTitle}>Title</div>
                        <div id="sentDateSortByInbox" className="sort-by-text sort-by-option" onClick={this.sortBySentDate}>Sent Date</div>
                    </div>
                </div>
                <div className="page-subheader">Inbox</div>
                <LetterDisplayView isReadOnly="true" letters={this.state.letters}></LetterDisplayView>
            </div>
        );
        if (this.state.isLoading) {
            webBody = (
                <div className="page-loading-spinner">
                    <div className="page-loading-spinner-container">
                        <CircularProgress size="5rem" />
                    </div>
                </div>
            );
        }
        return (
            <div className="letters-page-container">
                <AppHeader></AppHeader>
                <div className="letter-view-container">
                    <VerticalMenu></VerticalMenu>

                    {webBody}
                </div>
            </div>
        )
    }
}
InboxPage.contextType = userContext;
export default InboxPage;