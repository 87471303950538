import React from 'react';
import './../styles/AccountBody.css';
import './../App.css';
import { Auth } from 'aws-amplify';
import CircularProgress from '@mui/material/CircularProgress';

import {userContext} from './../userContext';

class AccountBody extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            isLoading: false,
            passwordChangeSuccess: false,
            emailSubscriptionChangeSuccess: false,
            showInvalidPassword: false,
            isLoadingEmailPreferenceUpdate: false,
            emailPreferenceEnabled: false
        }

        this.updatePassword = this.updatePassword.bind(this);
        this.updateEmailPreferences = this.updateEmailPreferences.bind(this);
        this.loadAccountData = this.loadAccountData.bind(this);
    }

    componentDidMount() {
        if (this.context.accountsService) {
            this.accountsService = this.context.accountsService;
            this.loadAccountData();
        }
    }

    loadAccountData() {
        this.accountsService.getAccount().then(function(response) {
            let emailPref = response.data.emailPreferences;
            if (emailPref === "true") {
                emailPref = true;
            } else {
                emailPref = false;
            }
            this.setState({
                emailPreferenceEnabled: emailPref
            });
        }.bind(this),
        function(error) {
            console.log(error);
        }.bind(this));
    }

    updateEmailPreferences(){
        let emailPref = document.getElementById("emailPreferencesInput").checked;
        this.setState({
            isLoadingEmailPreferenceUpdate: true,
            emailSubscriptionChangeSuccess: false
        });
        this.accountsService.updateEmailPreferences(emailPref).then(function(response) {
            this.setState({
                isLoadingEmailPreferenceUpdate: false,
                emailSubscriptionChangeSuccess: true
            });
        }.bind(this),
        function(error) {
            this.setState({
                isLoadingEmailPreferenceUpdate: false
            });
        }.bind(this));
    }

    isValidPasswords() {
        let validationMap = this.createValidationMap();
        for (let i in validationMap) {
            if (!validationMap[i].valid) {
                return false;
            }
        }
        return true;
    }

    createValidationMap() {
        let password = document.getElementById("newPasswordInputPrimary").value;
        return {
            length: {
                valid: password.length >= 8,
                elementId: "passwordLengthCriteria"
            },
            upperCase: {
                valid: /[A-Z]/.test(password),
                elementId: "upperCaseCharacterCriteria"
            },
            lowerCase: {
                valid: /[a-z]/.test(password),
                elementId: "lowerCaseCharacterCriteria"
            },
            specialCharacters: {
                valid: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password),
                elementId: "specialCharacterCriteria"
            },
            numberMatch: {
                valid: /[0-9]/.test(password),
                elementId: "numberCriteria"
            }
        }
    }

    async updatePassword() {
        let currentPassword = document.getElementById("currentUserPassword").value;
        let newPasswordPrimary = document.getElementById("newPasswordInputPrimary").value;
        let newPasswordSecondary = document.getElementById("newPasswordInputSecondary").value;
        if (this.isValidPasswords() && (newPasswordPrimary === newPasswordSecondary)) {
            this.setState({
                isLoading: true,
                showInvalidPassword: false
            });
            Auth.currentAuthenticatedUser()
                .then((user) => {
                    return Auth.changePassword(user, currentPassword, newPasswordPrimary);
                })
                .then(function(data){
                    this.setState({
                        isLoading: false,
                        passwordChangeSuccess: true,
                        showInvalidPassword: false
                    });
                }.bind(this))
                .catch((err) => console.log(err));
        } else {
            this.setState({
                showInvalidPassword: true
            });
        }
    }

    render() {
        let username = this.context.user[0] ? this.context.user[0][0].idToken.payload["cognito:username"] : undefined;
        let createdTimestamp = this.context.user[0] ? new Date(parseInt(this.context.user[0][0].idToken.payload.createdTimestamp)).toLocaleDateString('en-US') : undefined;
        let emailPreferenceInput = this.state.emailPreferenceEnabled ? <input id="emailPreferencesInput" type="checkbox" defaultChecked></input> : <input id="emailPreferencesInput" type="checkbox"></input>
        return (
            <div className="account-body-container">
                <userContext.Consumer>
                    {({logoutUser}) => {
                        return (
                            <div>
                                <div className="account-welcome-message">
                                    Hi {username}!
                                </div>
                                <div className="member-since-message">
                                    Member since {createdTimestamp}
                                </div>
                                <div className="reset-password-container">
                                    <div className="account-subheader">
                                        Reset Password
                                    </div>
                                    <div className="input-container">
                                        <input className="login-input" id="currentUserPassword" type="password" placeholder="Current Password"></input>
                                    </div>
                                    <div className="input-container">
                                        <input className="login-input" id="newPasswordInputPrimary" type="password" placeholder="New Password"></input>
                                    </div>
                                    <div className="input-container">
                                        <input className="login-input" id="newPasswordInputSecondary" type="password" placeholder="Confirm New Password"></input>
                                    </div>
                                    <div className="primary-button-wrapper">
                                        <button className="primary-button" onClick={this.updatePassword}>Reset Password</button>
                                    </div>
                                    <div hidden={!this.state.passwordChangeSuccess} className="modal-message-wrapper">
                                        <div className="modal-message success">
                                            Successfully reset password
                                        </div>
                                    </div>
                                    <div hidden={!this.state.showInvalidPassword} className="modal-message-wrapper">
                                        <div className="modal-message failure">
                                            Password criteria not met, password must include 1 capital letter, 1 lowercase letter, 1 number, 1 special character, and be at least 8 characters long
                                        </div>
                                    </div>
                                    <div className="loading-spinner-container" hidden={!this.state.isLoading}>
                                        <CircularProgress />
                                    </div>
                                </div>
                                <div className="email-subscription-preferences-container">
                                    <div>
                                        <label class="container">
                                            {emailPreferenceInput}
                                            <span class="checkmark">Email Notifications Enabled</span>
                                        </label>
                                    </div>
                                    <div className="primary-button-wrapper">
                                        <button className="primary-button" onClick={this.updateEmailPreferences}>Save Email Preferences</button>
                                    </div>
                                    <div hidden={!this.state.emailSubscriptionChangeSuccess} className="modal-message-wrapper">
                                        <div className="modal-message success">
                                            Successfully updated email preferences
                                        </div>
                                    </div>
                                    <div className="loading-spinner-container" hidden={!this.state.isLoadingEmailPreferenceUpdate}>
                                        <CircularProgress />
                                    </div>
                                </div>
                                <div className="logout-container">
                                    <div className="primary-button-wrapper">
                                        <button className="primary-button" onClick={logoutUser}>Logout</button>
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                </userContext.Consumer>
            </div>
        );
    }
}
AccountBody.contextType = userContext;
export default AccountBody;