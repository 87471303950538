import React from 'react';
import './../styles/AppFooter.css';
import './../App.css';
import appLogo from "../img/logos/footer-logo.png";

class AppFooter extends React.Component {
    render() {
        return (<div className="app-footer-container">
                    <div className="app-logo-container">
                        <img src={appLogo} className="footer-app-logo" alt="app-logo"></img>
                    </div>
                    <div className="app-footer-menu-items">
                        <div className="app-footer-menu-text">
                            None of the stationery provided by WriteNow can be shared, distributed, or used in any way not already provided by WriteNow. This includes downloading, printing, re-selling, or distributing in any form.
                        </div>
                    </div>
                </div>);
    }
}

export default AppFooter;