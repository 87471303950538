import React from 'react';
import './../styles/AccountPage.css';
import './../App.css';
import AppHeader from './../components/AppHeader';
import VerticalMenu from '../components/VerticalMenu';
import AccountBody from '../components/AccountBody';

class AccountPage extends React.Component {
    render() {
        return (
            <div>
                <AppHeader></AppHeader>
                <div className="letter-view-container">
                    <VerticalMenu></VerticalMenu>
                    <AccountBody></AccountBody>
                </div>
            </div>
        )
    }
}

export default AccountPage;
