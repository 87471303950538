import React from 'react';
import {userContext} from './userContext';
import './App.css';
import AppFooter from './components/AppFooter';
import WriteNowHomePage from './pages/WriteNowHomePage';
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MyLettersPage from './pages/MyLettersPage';
import RemindersPage from './pages/RemindersPage';
import StorePage from './pages/StorePage';
import PublicSingeLetterViewPage from './pages/PublicSingleLetterViewPage';
import PrivateSingeLetterViewPage from './pages/PrivateSingleLetterViewPage';
import ScheduledUpdatesPage from './pages/ScheduledUpdatesPage';
import AccountPage from './pages/AccountPage';
import LetterEditorPage from './pages/LetterEditorPage';
import ShareLetterPage from './pages/ShareLetterPage';
import InboxPage from './pages/InboxPage';
import { Link } from "react-router-dom";
import DocumentsService from './services/DocumentsService';
import AmplifyRequestService from './services/AmplifyRequestService.js';
import PublicDocumentsService from './services/PublicDocumentsService';
import WebFont from 'webfontloader';
import fontsImport from './font-data';
import AccountsService from './services/AccountsService';
import ScheduledUpdatesService from './services/ScheduledUpdatesService';
import StationaryPage from './pages/StationaryPage';

Amplify.configure(awsconfig);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      isLoadingWebsite: true
    };

    this.logout = this.logout.bind(this);
    this.login = this.login.bind(this);
    this.setUpServices = this.setUpServices.bind(this);

    this.secureBackendServiceName = "SecureBackend";
    this.publicBackendServiceName = "PublicBackend";
    this.amplifyRequestService = new AmplifyRequestService();
    this.publicDocumentsService = new PublicDocumentsService(this.amplifyRequestService, this.publicBackendServiceName);
    WebFont.load({
      google: {
        families: fontsImport
      }
    });
  }

  componentDidMount() {
    if (this.state.user.length === 0){
      this.setCurrentSession();
    }
  }

  async logout() {
    try {
      await Auth.signOut();
      this.setState({
        user: ""
      });
      document.getElementById("linkHome").click();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  async login() {
    this.setState({isSigninLoading: true});
    let email = document.getElementById("emailInput").value;
    let nameReplace = email.replace(/@.*$/,"");
    let username = nameReplace!==email ? nameReplace : null;
    let password = document.getElementById("passwordInputPrimary").value;
    try {
      const user = await Auth.signIn(username, password);
      this.setUpServices(user.signInUserSession.idToken);
      this.setState({
        user: [{
          idToken: user.signInUserSession.idToken,
          accessToken: user.signInUserSession.accessToken
        }],
        isSigninLoading: false,
        signinFailed: false
      });
    } catch (error) {
      this.setState({
        isSigninLoading: false,
        signinFailed: true
      });
    }
  }

  async setCurrentSession() {
    Auth.currentSession()
      .then(function(data){
        this.setUpServices(data.idToken);
        this.setState({
          user: [{
            idToken: data.idToken,
            accessToken: data.accessToken
          }],
          isLoadingWebsite: false
        })
      }.bind(this))
      .catch(function(err) {
        this.setState({
          isLoadingWebsite: false
        });
      }.bind(this));
  }

  setUpServices(idToken){
    this.amplifyRequestService.setIdToken(idToken);
    this.documentsService = new DocumentsService(this.amplifyRequestService, this.secureBackendServiceName);
    this.accountsService = new AccountsService(this.amplifyRequestService, this.secureBackendServiceName);
    this.scheduledUpdatesService = new ScheduledUpdatesService(this.amplifyRequestService, this.secureBackendServiceName);
  }

  render() {
    let userArray = this.state.user ? [this.state.user] : [];
    if (window.location.pathname !== "/") {
      this.redirectUrl = window.location.pathname;
    }
    const contextValue = {
      user: userArray,
      logoutUser: this.logout,
      loginUser: this.login,
      isSigninLoading: this.state.isSigninLoading,
      signinFailed: this.state.signinFailed,
      documentsService: this.documentsService,
      accountsService: this.accountsService,
      scheduledUpdatesService: this.scheduledUpdatesService,
      redirectPath: this.redirectUrl
    }

    // Prevents the user from seeing the home page 
    // briefly while the user token workflow is being executed
    if (this.state.isLoadingWebsite) {
      return (<div></div>)
    }
    return (
      <userContext.Provider value={contextValue}>
        <div className="App" hidden={this.state.isLoadingWebsite}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<WriteNowHomePage />} />
              <Route path="/profile" element={<MyLettersPage />}></Route>
              <Route path="/profile/letters" element={<MyLettersPage />} />
              <Route path="/profile/inbox" element={<InboxPage />} />
              <Route path="/profile/scheduled-shares" element={<ScheduledUpdatesPage />} />
              <Route path="/profile/reminders" element={<RemindersPage />} />
              <Route path="/profile/store" element={<StorePage />} />
              <Route path="/profile/stationery" element={<StationaryPage />} />
              <Route path="/letters/*" element={<PrivateSingeLetterViewPage />} />
              <Route path="/view/*" element={<PublicSingeLetterViewPage publicDocumentsService={this.publicDocumentsService}></PublicSingeLetterViewPage>} />
              <Route path="/edit/*" element={<LetterEditorPage />} />
              <Route path="/account/*" element={<AccountPage />} />
              <Route path="/share/*" element={<ShareLetterPage />} />
              {/* <Route path="*" element={<PublicHomePage />} /> */}
            </Routes>
            <Link id="linkHome" to="/"></Link>
          </BrowserRouter>
          <AppFooter></AppFooter>
        </div>
      </userContext.Provider>
    );
  }
}
export default App;