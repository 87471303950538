import React from 'react';
import './../styles/ScheduledUpdatesPage.css';
import './../App.css';
import AppHeader from './../components/AppHeader';
import VerticalMenu from '../components/VerticalMenu';
import ScheduledUpdatesBody from '../components/ScheduledUpdatesBody';

class ScheduledUpdatesPage extends React.Component {
    render() {
        return (
            <div>
                <AppHeader></AppHeader>
                <div className="letter-view-container">
                    <VerticalMenu></VerticalMenu>
                    <ScheduledUpdatesBody></ScheduledUpdatesBody>
                </div>
            </div>
        )
    }
}

export default ScheduledUpdatesPage;
