class AccountsService {
    constructor(amplifyRequestService, apiName) {
      this.amplifyRequestService = amplifyRequestService;
      this.apiName = apiName;
    }

    getAccount() {
      return this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/accounts', "GET");
    }
  
    updateEmailPreferences(emailPreference) {
      let request = {
        body: emailPreference
      }
      return this.amplifyRequestService.requestWithAmplifyToken(this.apiName, '/accounts', "POST", request);
    }
}
  
export default AccountsService;