import React from 'react';
import './../styles/StationaryItem.css';
import './../App.css';
import {userContext} from './../userContext';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';

var PLACEHOLDER_TEXT = "Write your letter here!"
var AUTH_PRIVATE = "PRIVATE";
var DEFAULT_FONT_FAMILY = "Moon Dance";

class StationaryItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
        
        this.createNewLetterFromStationary = this.createNewLetterFromStationary.bind(this);
    }

    componentDidMount() {
        if (this.context.documentsService) {
            this.documentsService = this.context.documentsService;
        }
    }

    createNewLetterFromStationary() {
        if (this.documentsService) {
            this.setState({
                isLoading: true
            });
            let millisNow = new Date().getTime();
            let newDoc = {
                title: "Letter Created From Stationary",
                createdDate: millisNow,
                body: PLACEHOLDER_TEXT,
                fontFamily: DEFAULT_FONT_FAMILY,
                stationaryId: this.props.stationaryId,
                sentDate: 0,
                authorization: AUTH_PRIVATE,
                lastModifiedTimestamp: millisNow,
                receiverEmail: "",
                imageUrl: "",
                type: "DOCUMENT"
            }
            this.documentsService.saveDocument(newDoc).then(function(
                response){
                    let id = response.data.documentId;
                    // Set parent documentId for redirection to letter editor
                    this.props.setDocumentId(id);
                }.bind(this),
                function(error){
                    console.log(error);
                    this.setState({
                        isSavingDocument: false
                    });
                }.bind(this));
        }
    }

    render() {
        return (
            <Tooltip title="Create a Letter From Stationary">
                <div onClick={this.createNewLetterFromStationary} className="letter-list-item-container document stationary" style={{ backgroundImage: `url(${this.props.stationaryId})` }}>
                    <div className="loading-spinner-container" hidden={!this.state.isLoading}>
                        <CircularProgress />
                    </div>
                </div>
            </Tooltip>
        );
    }
}

StationaryItem.contextType = userContext;
export default StationaryItem;