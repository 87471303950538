import React from 'react';
import './../styles/ShareLetterPage.css';
import './../App.css';
import AppHeader from './../components/AppHeader';
import VerticalMenu from '../components/VerticalMenu';
import ShareLetterNavigation from '../components/ShareLetterNavigation';
import ShareLetterType from '../components/ShareLetterType';
import ShareLetterReceiver from '../components/ShareLetterReceiver';
import ShareLetterSummary from '../components/ShareLetterSummary';
import CircularProgress from '@mui/material/CircularProgress';
import QRCode from "react-qr-code";

import {userContext} from './../userContext';
import ShareLetterDate from '../components/ShareLetterDate';

var TYPE_VIEW = "TYPE_VIEW";
var RECEIVER_VIEW = "RECEIVER_VIEW";
var DATE_VIEW = "DATE_VIEW";
var SUMMARY_VIEW = "SUMMARY_VIEW";
var SUCCESS_VIEW = "SUCCESS_VIEW";
var SCHEDULE_SUCCESS_VIEW = "SCHEDULE_SUCCESS_VIEW";
var PRIVATE_AUTH = "PRIVATE";
var PUBLIC_AUTH = "PUBLIC";

var idPropMap = {
    TYPE_VIEW: "howShareSelection",
    RECEIVER_VIEW: "whoShareSelection",
    DATE_VIEW: "dateShareSelection",
    SUMMARY_VIEW: "summaryShareSelection",
    URL: "urlShareTypeOption",
    QR_CODE: "qrCodeShareTypeOption",
    SECRET_KEY: "secretCodeShareTypeOption"
}

class ShareLetterPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            view: TYPE_VIEW,
            isLoadingPage: true,
            notifyReceiver: false
        }

        this.setView = this.setView.bind(this);
        this.setShareType = this.setShareType.bind(this);
        this.setReceiverDetails = this.setReceiverDetails.bind(this);
        this.setDate = this.setDate.bind(this);
        this.shareLetter = this.shareLetter.bind(this);
        this.scheduleLetterShare = this.scheduleLetterShare.bind(this);
    }

    componentDidMount() {
        this.documentsService = this.context.documentsService;
        this.scheduledUpdatesService = this.context.scheduledUpdatesService;
        let pathParams = window.location.pathname.split("/");
        let letterId = undefined;
        if (pathParams.length > 2) {
            letterId = pathParams[2];
        }
        if (this.documentsService && letterId) {
            this.documentsService.getDocumentById(letterId).then(
                function(response){
                    let letter = response.data.response;
                    this.setState({
                        letterAuthorization: letter.authorization,
                        receiver: letter.receiverEmail,
                        letter: [letter],
                        isLoadingPage: false
                    });
                }.bind(this),
                function(error){
                    console.log(error);
                    this.setState({
                        isLoadingPage: false
                    });
                }.bind(this));
        }
    }

    setView(event, context) {
        let oldSelection = idPropMap[this.state.view];
        let newSelection = idPropMap[event];
        if (oldSelection) {
            document.getElementById(oldSelection).classList.remove("share-nav-selected");
        }
        if (newSelection) {
            document.getElementById(newSelection).classList.add("share-nav-selected");
        }
        this.setState({
            view: event
        });
    }

    setShareType(event, context) {
        let oldSelection = idPropMap[this.state.shareType];
        let newSelection = idPropMap[event];
        if (oldSelection) {
            document.getElementById(oldSelection).classList.remove("share-type-selected");
        }
        document.getElementById(newSelection).classList.add("share-type-selected");
        this.setState({
            shareType: event
        });
        this.setView(RECEIVER_VIEW);
    }

    setReceiverDetails(email, auth, notifyThem) {
        this.setState({
            receiver: email,
            letterAuthorization: auth,
            notifyReceiver: notifyThem
        });
        this.setView(DATE_VIEW);
    }

    setDate(date) {
        this.setState({
            sentDate: date
        });
        this.setView(SUMMARY_VIEW);
    }

    shareLetter() {
        this.setState({
            isLoadingPage: true
        });
        this.documentsService.shareLetter(
            this.state.letter[0].id, 
            this.state.letterAuthorization ? this.state.letterAuthorization : PRIVATE_AUTH, 
            this.state.shareType, 
            this.state.receiver,
            this.state.notifyReceiver
        ).then(function(response){
            let urlPath = "/letters/";
            if (this.state.letterAuthorization === PUBLIC_AUTH) {
                urlPath = "/view/";
            }
            let urlValue = window.location.origin + urlPath + this.state.letter[0].id
            if (this.state.shareType === "URL") {
                let url = document.getElementById("shareUrlInput");
                url.value = urlValue;
                this.setState({
                    view: SUCCESS_VIEW,
                    isLoadingPage: false
                });
            }
            if (this.state.shareType === "QR_CODE") {
                this.setState({
                    view: SUCCESS_VIEW,
                    isLoadingPage: false,
                    qrCodeValue: urlValue
                });
            }
            if (this.state.shareType === "SECRET_KEY") {
                this.setState({
                    view: SUCCESS_VIEW,
                    isLoadingPage: false,
                    secretKey: response.data.Item.letterCode.S
                });
            }
        }.bind(this),
        function(error){
            // Need to handle error page
            console.log(error);
            this.setState({
                isLoadingPage: false
            });
        }.bind(this));
    }

    scheduleLetterShare() {
        this.setState({
            isLoadingPage: true
        });
        this.scheduledUpdatesService.saveScheduledUpdate({
            letterId: this.state.letter[0].id,
            authorization: this.state.letterAuthorization ? this.state.letterAuthorization : PRIVATE_AUTH,
            shareType: this.state.shareType,
            receiver: this.state.receiver,
            date: this.getFormattedDate(this.state.sentDate),
            notifyReceiver: this.state.notifyReceiver
        }).then(function(response){
            this.setState({
                view: SCHEDULE_SUCCESS_VIEW,
                isLoadingPage: false
            });
        }.bind(this),
        function(error){
            // Need to handle error page
            console.log(error);
            this.setState({
                isLoadingPage: false
            });
        }.bind(this));
    }

    getFormattedDate(date) {
        var year = date.getFullYear();
      
        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
      
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        
        return month + '/' + day + '/' + year;
    }

    copyShareURL() {
        let url = document.getElementById("shareUrlInput");
        url.select();
        url.setSelectionRange(0, 99999); // For mobile devices
        navigator.clipboard.writeText(url.value);
    }

    render() {
        let scheduledUpdatesLink = window.location.origin + "/profile/scheduled-shares";
        return (
            <div>
                <AppHeader></AppHeader>
                <div className="letter-view-container">
                    <VerticalMenu></VerticalMenu>
                    <div className="page-loading-spinner" hidden={!this.state.isLoadingPage}>
                        <div className="page-loading-spinner-container">
                            <CircularProgress size="5rem" />
                        </div>
                    </div>
                    <div className="letter-share-container" hidden={this.state.isLoadingPage}>
                        <div className="page-subheader">Share Letter</div>
                        <ShareLetterNavigation setView={this.setView}></ShareLetterNavigation>
                        <div hidden={!(this.state.view === TYPE_VIEW)}>
                            <ShareLetterType setShareType={this.setShareType}></ShareLetterType>
                        </div>
                        <div hidden={!(this.state.view === RECEIVER_VIEW)}>
                            <ShareLetterReceiver 
                                shareType={this.state.shareType} 
                                authorization={this.state.letterAuthorization} 
                                receiver={this.state.receiver}
                                setReceiverDetails={this.setReceiverDetails}
                            ></ShareLetterReceiver>
                        </div>
                        <div hidden={!(this.state.view === DATE_VIEW)}>
                            <ShareLetterDate setDate={this.setDate}></ShareLetterDate>
                        </div>
                        <div hidden={!(this.state.view === SUMMARY_VIEW)}>
                            <ShareLetterSummary
                                shareType={this.state.shareType}
                                receiver={this.state.receiver}
                                letterAuthorization={this.state.letterAuthorization}
                                sentDate={this.state.sentDate}
                                letter={this.state.letter}
                                shareLetter={this.shareLetter}
                                scheduleLetterShare={this.scheduleLetterShare}
                            ></ShareLetterSummary>
                        </div>
                        <div hidden={!(this.state.view === SUCCESS_VIEW)}>
                            <div className="successful-share-message">Your letter was successful shared! We've emailed you a confirmation and you can see the details below.</div>
                            <div hidden={this.state.shareType !== "URL"} className="share-container">
                                <div className="share-url-input-container">
                                    <input id="shareUrlInput" type="text" readOnly className="share-url-input"></input>
                                    <button onClick={this.copyShareURL} className="share-url-copy-button">Copy</button>                  
                                </div>
                            </div>
                            <div hidden={this.state.shareType !== "QR_CODE"} className="share-container">
                                <div style={{ background: '#F8FBFD', padding: '5px' }}>
                                    <QRCode
                                        className="qr-code"
                                        value={this.state.qrCodeValue ? this.state.qrCodeValue : "https://google.com"}
                                    />
                                </div>   
                            </div>
                            <div hidden={this.state.shareType !== "SECRET_KEY"} className="share-container">
                                <div className="secret-code-body-text">
                                    Your secret code is
                                </div>
                                <div className="secret-code">
                                    {this.state.secretKey}
                                </div>
                            </div>
                        </div>
                        <div className="schedule-letter-success" hidden={!(this.state.view === SCHEDULE_SUCCESS_VIEW)}>
                            Your letter has been successfully scheduled! Navigate to the <a href={scheduledUpdatesLink} className="schedule-update-link">scheduled updates page</a> to view all your shares that are set to be executed.
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ShareLetterPage.contextType = userContext;
export default ShareLetterPage;