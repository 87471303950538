import React from 'react';
import './../styles/LettersPage.css';
import './../App.css';
import AppHeader from './../components/AppHeader';
import LetterDisplayView from '../components/LetterDisplayView';
import VerticalMenu from '../components/VerticalMenu';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from "react-router-dom";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ErrorIcon from '@mui/icons-material/Error';

import {userContext} from './../userContext';

var CREATED_DATE = "CREATED_DATE";
var TITLE = "TITLE";
var SENT_DATE = "SENT_DATE";
var DRAFT = "DRAFT";
var SENT = "SENT";
var LOCKED = "LOCKED";

class MyLettersPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            letters: [],
            sortByProperty: CREATED_DATE,
            isLoadingPage: true,
            showErrorView: false,
            filters: [DRAFT]
        }

        this.loadLetters = this.loadLetters.bind(this);
        this.sortByCreatedDate = this.sortByCreatedDate.bind(this);
        this.sortByTitle = this.sortByTitle.bind(this);
        this.sortBySentDate = this.sortBySentDate.bind(this);
        this.refreshListView = this.refreshListView.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
    }

    componentDidMount() {
        this.loadLetters();
    }

    loadLetters() {
        if (this.context.documentsService) {
            this.context.documentsService.getAllDocuments().then(function(response){
                let letters = response.data.response;
                let filteredLetters = letters.filter(function(letter) {
                    if (this.state && this.state.filters && this.state.filters.length > 0) {
                        let shouldReturn = false;
                        if (this.state.filters.includes(DRAFT)) {
                            shouldReturn = shouldReturn || (letter.sentTimestamp === 0)
                        }
                        if (this.state.filters.includes(SENT)) {
                            shouldReturn = shouldReturn || (letter.sentTimestamp > 0)
                        }
                        if (this.state.filters.includes(LOCKED)) {
                            shouldReturn = shouldReturn || (letter.lockStatus === "LOCKED")
                        }
                        return shouldReturn;
                    }
                    return true;
                }.bind(this));
                this.setState({
                    letters: filteredLetters,
                    isLoadingPage: false,
                    showErrorView: false
                });
            }.bind(this), function(error){
                this.setState({
                    showErrorView: true,
                    isLoadingPage: false
                });
            }.bind(this));
        }
    }

    sortByCreatedDate() {
        if (this.state.sortByProperty !== CREATED_DATE) {
            let letters = this.state.letters.sort(function(a, b){
                return parseInt(a.createdTimestamp) - parseInt(b.createdTimestamp);
            });
            this.setSelectedSort(CREATED_DATE);
            this.setState({
                letters: letters,
                sortByProperty: CREATED_DATE
            });
        }
    }

    sortByTitle() {
        if (this.state.sortByProperty !== TITLE) {
            let letters = this.state.letters.sort(function(a, b){
                if (a.title < b.title) {
                    return -1;
                }
                if (a.title > b.title) {
                    return 1;
                }
                return 0;
            });
            this.setSelectedSort(TITLE);
            this.setState({
                letters: letters,
                sortByProperty: TITLE
            });
        }
    }

    sortBySentDate() {
        if (this.state.sortByProperty !== SENT_DATE) {
            let letters = this.state.letters.sort(function(a, b){
                return parseInt(a.sentTimestamp) - parseInt(b.sentTimestamp);
            });
            this.setSelectedSort(SENT_DATE);
            this.setState({
                letters: letters,
                sortByProperty: SENT_DATE
            });
        }
    }

    setSelectedSort(sort) {
        if (sort === CREATED_DATE) {
            document.getElementById("createdDateSortBy").classList.add("sort-selected");
            document.getElementById("titleSortBy").classList.remove("sort-selected");
            document.getElementById("sentDateSortBy").classList.remove("sort-selected");
        } else if (sort === TITLE) {
            document.getElementById("createdDateSortBy").classList.remove("sort-selected");
            document.getElementById("titleSortBy").classList.add("sort-selected");
            document.getElementById("sentDateSortBy").classList.remove("sort-selected");
        } else if (sort === SENT_DATE) {
            document.getElementById("createdDateSortBy").classList.remove("sort-selected");
            document.getElementById("titleSortBy").classList.remove("sort-selected");
            document.getElementById("sentDateSortBy").classList.add("sort-selected");
        }
    }

    updateFilter(event, context) {
        let targetClasses = context.target.classList;
        let updatedFilter = this.state.filters;
        if (this.state.filters.includes(event)) {
            updatedFilter = [];
            for (let i in this.state.filters) {
                let filter = this.state.filters[i];
                if (filter !== event) {
                    updatedFilter.push(filter);
                }
            }
            targetClasses.add("filter-button-disabled");
        } else {
            updatedFilter.push(event);
            targetClasses.remove("filter-button-disabled");
        }
        this.setState({
            filters: updatedFilter
        });
        this.loadLetters();
    }

    refreshListView() {
        this.loadLetters();
    }

    render() {
        let webBody = (
            <div className="view-container">
                <div className="letter-view-header">
                    <div>
                        <div className="sort-by-text sort-by-header">Filter: </div>
                        <div className="primary-button-wrapper filter-button">
                            <button className="primary-button" onClick={this.updateFilter.bind(this, DRAFT)}>Drafts</button>
                        </div>
                        <div className="primary-button-wrapper filter-button">
                            <button className="primary-button filter-button-disabled" onClick={this.updateFilter.bind(this, SENT)}>Sent</button>
                        </div>
                        <div className="primary-button-wrapper filter-button">
                            <button className="primary-button filter-button-disabled" onClick={this.updateFilter.bind(this, LOCKED)}>Locked</button>
                        </div>
                    </div>
                    <div>
                        <div className="sort-by-text sort-by-header">Sort By: </div>
                        <div id="createdDateSortBy" className="sort-by-text sort-by-option sort-selected" onClick={this.sortByCreatedDate}>Created Date</div>
                        <div id="titleSortBy" className="sort-by-text sort-by-option" onClick={this.sortByTitle}>Title</div>
                        <div id="sentDateSortBy" className="sort-by-text sort-by-option" onClick={this.sortBySentDate}>Sent Date</div>
                    </div>
                    {/* <div className="horizontal-menu-icon-button flex-align-right">
                        <div>
                            <Tooltip title="Create New Folder">
                                <CreateNewFolderIcon style={{ fill: '#041A2D' }} sx={{ fontSize: 30 }}/>
                            </Tooltip>
                        </div>
                    </div> */}
                </div>
                <div className="page-subheader">My Letters</div>
                <LetterDisplayView letters={this.state.letters} refreshListView={this.refreshListView}></LetterDisplayView>
                <div className="empty-letters-page-container" hidden={this.state.letters.length !== 0 || this.state.showErrorView}>
                    <div>You have no letters, but don't wait, Write Now!</div>
                    <div className="empty-letters-page-icon">
                        <Link to="/profile/stationery">
                            <div>
                                <Tooltip title="Create New Letter">
                                    <NoteAddIcon sx={{ fontSize: 30 }}/>
                                </Tooltip>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="empty-letters-page-container error-loading" hidden={!this.state.showErrorView}>
                    <div>An unknown problem occurred, please try again later :/</div>
                    <div className="empty-letters-page-icon">
                        <Tooltip title="Error">
                            <ErrorIcon sx={{ fontSize: 30 }}/>
                        </Tooltip>
                    </div>
                </div>
            </div>
        );
        if (this.state.isLoadingPage) {
            webBody = (
                <div className="page-loading-spinner">
                    <div className="page-loading-spinner-container">
                        <CircularProgress size="5rem" />
                    </div>
                </div>
            );
        }
        return (
            <div className="letters-page-container">
                <AppHeader></AppHeader>
                <div className="letter-view-container">
                    <VerticalMenu></VerticalMenu>
                    {webBody}
                </div>
            </div>
        )
    }
}
MyLettersPage.contextType = userContext;
export default MyLettersPage;