import React from 'react';
import './../styles/ScheduledUpdatesBody.css';
import './../App.css';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@mui/material/CircularProgress';

import {userContext} from './../userContext';

class ScheduledUpdatesBody extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isLoadingPage: true,
            isLoadingDelete: false,
            modalOpen: false,
            showDeleteFailedMessage: false,
            tableRows: []
        }

        this.openDeleteScheduledUpdateModal = this.openDeleteScheduledUpdateModal.bind(this);
        this.deleteScheduledUpdate = this.deleteScheduledUpdate.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        if (this.context.scheduledUpdatesService) {
            this.scheduledUpdatesService = this.context.scheduledUpdatesService;
            this.loadScheduledUpdates();
        }
    }

    getFormattedDate(date) {
        var year = date.getFullYear();
      
        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
      
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        
        return month + '/' + day + '/' + year;
    }

    openDeleteScheduledUpdateModal(event, context) {
        this.setState({
            modalOpen: true,
            idToDelete: event
        });
    }

    loadScheduledUpdates() {
        this.scheduledUpdatesService.getScheduledUpdates().then(function(response) {
            let rows = [];
            let scheduledUpdates = response.data;
            for (let i in scheduledUpdates) {
                let update = scheduledUpdates[i];
                let letterLink = window.location.origin + "/edit/" + update.id;
                let idRef = update.id.substring(update.id.length - 6);
                let authorizationString = update.authorization === "PUBLIC" ? "Publicly" : "Privately";
                let key = "scheduledUpdate-" + update.id;
                rows.push(
                    <tr className="updates-table-row" key={key}>
                        <td className="updates-table-description schedule-update-link"><a href={letterLink}>{idRef}</a></td>
                        <td className="updates-table-description">{update.dateField}</td>
                        <td className="updates-table-description">{authorizationString}</td>
                        <td className="updates-table-description">{update.receiver}</td>
                        <td className="updates-table-description">{update.shareType}</td>
                        <td className="updates-table-description delete-row" onClick={this.openDeleteScheduledUpdateModal.bind(this, update.id)}><DeleteIcon></DeleteIcon></td>
                    </tr>
                )
            }
            this.setState({
                isLoadingPage: false,
                tableRows: rows
            });
        }.bind(this),
        function(error) {
            console.log(error);
            this.setState({
                isLoadingPage: false
            });
        }.bind(this));
    }

    closeModal() {
        this.setState({
            modalOpen: false
        });
    }

    deleteScheduledUpdate() {
        if (this.state.idToDelete) {
            this.setState({
                isLoadingDelete: true
            });
            this.scheduledUpdatesService.deleteScheduledUpdate(this.state.idToDelete).then(function(response) {
                this.setState({
                    isLoadingDelete: false,
                    modalOpen: false
                });
            }.bind(this),
            function(error) {
                console.log(error);
                this.setState({
                    isLoadingDelete: false,
                    showDeleteFailedMessage: true,
                    modalOpen: false
                });
            }.bind(this));
        }
    }

    render() {
        return (
            <div className="scheduled-updates-body-container">
                <div hidden={this.state.isLoadingPage}>
                    <div className="page-subheader">Reminders and Scheduled Sharing</div>
                    <div className="scheduled-updates-empty-container" hidden={this.state.tableRows.length > 0}>
                        You have no scheduled sharing for any letters. You can create schedules by sharing a letter in the future and they will show up here.
                    </div>
                    <div className="scheduled-updates-table-container" hidden={this.state.tableRows.length === 0}>
                        <div>Below you'll find all the shares you've scheduled to occur, you can delete them or you can update them by scheduling a new share from the letter page</div>
                        <table>
                            <thead>
                                <tr className="updates-table-row-header">
                                    <th className="updates-table-row-header-label">Letter</th>
                                    <th className="updates-table-row-header-label">Scheduled Date</th>
                                    <th className="updates-table-row-header-label">Authorization</th>
                                    <th className="updates-table-row-header-label">Receiver</th>
                                    <th className="updates-table-row-header-label">Share Type</th>
                                    <th className="updates-table-row-header-label"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tableRows}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="page-loading-spinner" hidden={!this.state.isLoadingPage}>
                    <div className="page-loading-spinner-container">
                        <CircularProgress size="5rem" />
                    </div>
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.modalOpen}
                    onClose={this.closeModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.modalOpen}>
                        <div className="login-modal-wrapper">
                            <div className="modal-component-title">
                                Delete Scheduled Share?
                            </div>
                            <div className="delete-body-text">Are you sure you want to delete your scheduled share? This cannot be undone, but you can create a new one by sharing the letter again.</div>
                            <div className="modal-button-container">
                                <div className="primary-button-container horizontal-button">
                                    <button className="primary-button delete-button" onClick={this.deleteScheduledUpdate}>Delete</button>
                                </div>
                                <div className="primary-button-container horizontal-button">
                                    <button className="primary-button" onClick={this.closeModal}>Cancel</button>
                                </div>
                                <div className="loading-spinner-container" hidden={!this.state.isLoadingDelete}>
                                    <CircularProgress />
                                </div>
                            </div>
                            <div hidden={!this.state.showDeleteFailedMessage} className="modal-message-wrapper">
                                <div className="modal-message failure">
                                    Failed to delete scheduled update, please try again later
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </div>
        );
    }
}
ScheduledUpdatesBody.contextType = userContext;
export default ScheduledUpdatesBody;