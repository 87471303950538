import React from 'react';
import './../styles/ShareLetterType.css';
import './../App.css';
import LinkIcon from '@mui/icons-material/Link';
import QrCodeIcon from '@mui/icons-material/QrCode';
import KeyIcon from '@mui/icons-material/Key';

var URL_SHARE_TYPE = "URL";
var QR_CODE_SHARE_TYPE = "QR_CODE";
var SECRET_KEY_TYPE = "SECRET_KEY";

class ShareLetterType extends React.Component {
    render() {
        return (
            <div className="share-menu-section">
                <div className="share-menu-section-header">
                    Select How You Want To Share
                </div>
                <div id="urlShareTypeOption" className="share-option" onClick={this.props.setShareType.bind(this, URL_SHARE_TYPE)}>
                    <div className="share-option-icon">
                        <LinkIcon sx={{ fontSize: 30 }}/>
                    </div>
                    <div className="share-option-description-container">
                        <div className="share-option-title">
                            Share via URL
                        </div>
                        <div className="share-option-body">
                            Generate a URL and share when you are ready
                        </div>
                    </div>
                </div>
                <div id="qrCodeShareTypeOption" className="share-option" onClick={this.props.setShareType.bind(this, QR_CODE_SHARE_TYPE)}>
                    <div className="share-option-icon">
                        <QrCodeIcon sx={{ fontSize: 30 }}/>
                    </div>
                    <div className="share-option-description-container">
                        <div className="share-option-title">
                            Share via QR Code
                        </div>
                        <div className="share-option-body">
                            Download a QR code that you can share when you are ready
                        </div>
                    </div>
                </div>
                <div id="secretCodeShareTypeOption" className="share-option" onClick={this.props.setShareType.bind(this, SECRET_KEY_TYPE)}>
                    <div className="share-option-icon">
                        <KeyIcon sx={{ fontSize: 30 }}/>
                    </div>
                    <div className="share-option-description-container">
                        <div className="share-option-title">
                            Share via Secret Key
                        </div>
                        <div className="share-option-body">
                            Set or randomly generate a 6 character code you share with your receiver whenever you are ready
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShareLetterType;
