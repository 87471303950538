import React from 'react';
import './../styles/WelcomeView.css';
import './../App.css';
import welcomeBackground from "../img/background-logo.png";

class WelcomeView extends React.Component {
    render() {
        return (
            <div className="welcome-view-container" style={{ backgroundImage: `url(${welcomeBackground})` }}>
                <div className="welcome-view-info-container">
                    <div className="welcome-view-main-text">
                        Securly Write, Send, and Store All Your Letters Digitally
                    </div>
                    <div>
                        <div className="primary-button-wrapper">
                            <button className="primary-button" onClick={this.props.openLoginModal}>Start Writing</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WelcomeView;