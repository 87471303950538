import React from 'react';
import './../styles/LetterEditorPage.css';
import './../App.css';
import AppHeader from './../components/AppHeader';
import VerticalMenu from '../components/VerticalMenu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CircularProgress from '@mui/material/CircularProgress';
import fontsImport from './../font-data';
import { Navigate } from "react-router-dom";

import {userContext} from './../userContext';

var PLACEHOLDER_TEXT = "Write your letter here!"
var AUTH_PRIVATE = "PRIVATE";
var DEFAULT_FONT_FAMILY = "Moon Dance";
var DEFAULT_TITLE = "Untitled";
var _20_SECONDS = 20000;
var STATIONARY_BASE_URL = "https://stationary-bucket.s3.us-east-1.amazonaws.com/stationary/";
var DEFAULT_STATIONARY_URL = STATIONARY_BASE_URL + "love-gradient.PNG";

class LetterEditorPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            title: DEFAULT_TITLE,
            body: PLACEHOLDER_TEXT,
            fontFamily: DEFAULT_FONT_FAMILY,
            stationaryId: DEFAULT_STATIONARY_URL,
            sentDate: 0,
            fontFamilySelectOpen: false,
            stationary: DEFAULT_STATIONARY_URL,
            isSavingDocument: false,
            isLoadingPage: true,
            shareModalOpen: false,
            stationaryOptions: []
        }

        this.updateFontFamily = this.updateFontFamily.bind(this);
        this.toggleFontFamilySelect = this.toggleFontFamilySelect.bind(this);
        this.updateText = this.updateText.bind(this);
        this.save = this.save.bind(this);
        this.redirectToShare = this.redirectToShare.bind(this);
        this.resetLetterEditor = this.resetLetterEditor.bind(this);
    }

    componentDidMount() {
        this.documentsService = this.context.documentsService;
        let pathParams = window.location.pathname.split("/");
        let letterId = undefined;
        if (pathParams.length > 2) {
            letterId = pathParams[2];
        }
        if (this.documentsService && letterId) {
            this.documentsService.getDocumentById(letterId).then(
                function(response){
                    let letter = response.data.response;
                    document.getElementById("letterTextEditor").value = letter.body;
                    document.getElementById("documentTitleInput").value = letter.title;
                    this.setState({
                        id: letter.id,
                        title: letter.title,
                        createdDate: letter.createdDate,
                        body: letter.body,
                        fontFamily: letter.fontFamily,
                        stationaryId: letter.stationaryId,
                        stationary: letter.stationaryId,
                        sentDate: letter.sentTimestamp,
                        authorization: letter.authorization,
                        lastModifiedTimestamp: letter.lastModifiedTimestamp,
                        receiverEmail: letter.receiverEmail,
                        imageUrl: letter.imageUrl,
                        isLoadingPage: false
                    });
                    this.generateStationaryOptions();
                }.bind(this),
                function(error){
                    console.log(error);
                    this.setState({
                        isLoadingPage: false
                    });
                }.bind(this));
        } else {
            let millisNow = new Date().getTime();
            this.setState({
                id: "",
                title: DEFAULT_TITLE,
                createdDate: millisNow,
                body: PLACEHOLDER_TEXT,
                fontFamily: DEFAULT_FONT_FAMILY,
                stationaryId: DEFAULT_STATIONARY_URL,
                sentDate: 0,
                authorization: AUTH_PRIVATE,
                lastModifiedTimestamp: millisNow,
                stationary: DEFAULT_STATIONARY_URL,
                autoSaveTime: millisNow,
                isLoadingPage: false
            });
            this.generateStationaryOptions();
        }
    }

    generateFontOptions() {
        let fonts = fontsImport;
        let options = [];
        for (let i in fonts){
            let font = fonts[i];
            let key = "font-family-select-" + fonts[i];
            options.push(
                <div 
                    key={key} 
                    className="font-option" 
                    style={{ fontFamily: `${font}` }}
                    onClick={this.updateFontFamily.bind(this, font)}
                >
                    {font}
                </div>
            )
        }
        return options;
    }

    generateStationaryOptions() {
        if (this.documentsService) {
            this.documentsService.getStationary().then(
                function(response){
                    let stationaries = response.data;
                    let options = [];
                    for (let i in stationaries){
                        let stationary = stationaries[i];
                        let key = "stationary-select-" + stationary.id;
                        let stationaryUrl = STATIONARY_BASE_URL + stationary.stationaryKey;
                        let stationaryClass = "stationary-option";
                        if (this.state.stationary === stationaryUrl) {
                            stationaryClass += " stationary-option-selected";
                        }
                        options.push(
                            <div key={key} className={stationaryClass} onClick={this.updateStationary.bind(this, stationary)}>
                                <img src={stationaryUrl} className="stationary-option-img" alt="stationary-select"></img>
                            </div>
                        )
                    }
                    this.setState({
                        stationaryOptions: options
                    });
                }.bind(this),
                function(error){
                    console.log(error);
                }
            );
        }
    }

    updateFontFamily(fontFamily, event){
        this.setState({
            fontFamily: fontFamily,
            fontFamilySelectOpen: false
        });
    }

    updateStationary(stationary, event){
        let options = document.getElementsByClassName("stationary-option");
        for (let i in options) {
            let option = options[i];
            if (option.children && option.children[0].src.includes(stationary.stationaryKey)) {
                option.classList.add("stationary-option-selected");
            } else {
                if (option.classList) {
                    option.classList.remove("stationary-option-selected");
                }
            }
        }
        this.setState({
            stationary: STATIONARY_BASE_URL + stationary.stationaryKey
        });
    }

    updateText(event) {
        let keyPress = event.key;
        let textArea = document.getElementById('letterTextEditor');
        if (textArea) {
            let newText = textArea.value;
            if (keyPress === "Enter") {
                newText = newText + "\n";
            }
            if (newText.length === 0){
                this.setState({
                    body: PLACEHOLDER_TEXT
                });
            }
            this.setState({
                body: newText
            });
            if ((this.state.title !== DEFAULT_TITLE) && (new Date().getTime() - this.state.lastModifiedTimestamp) > _20_SECONDS) {
                // autosaving
                this.save();
            }
        }
    }

    save() {
        this.setState({
            isSavingDocument: true
        });
        let millisNow = new Date().getTime();
        let documentToSave = {
            title: document.getElementById("documentTitleInput").value ? document.getElementById("documentTitleInput").value : DEFAULT_TITLE,
            createdDate: millisNow,
            body: document.getElementById("letterTextEditor").value ? document.getElementById("letterTextEditor").value : "",
            fontFamily: this.state.fontFamily,
            stationaryId: this.state.stationary,
            sentDate: this.state.sentDate ? this.state.sentDate : 0,
            authorization: this.state.authorization,
            lastModifiedTimestamp: millisNow,
            receiverEmail: this.state.receiverEmail,
            imageUrl: this.state.imageUrl,
            type: "DOCUMENT"
        }
        if (this.state.id) {
            documentToSave.id = this.state.id;
        }
        this.documentsService.saveDocument(documentToSave).then(function(
            response){
                this.setState({
                    isSavingDocument: false,
                    id: response.data.documentId,
                    lastModifiedTimestamp: parseInt(response.data.lastModifiedTimestamp)
                });
            }.bind(this),
            function(error){
                console.log(error);
                this.setState({
                    isSavingDocument: false
                });
            }.bind(this));
    }

    toggleFontFamilySelect() {
        this.setState({fontFamilySelectOpen: !this.state.fontFamilySelectOpen});
    }

    redirectToShare() {
        this.save();
        this.setState({
            redirectToShare: true
        });
    }

    resetLetterEditor() {
        this.setState({
            id: "",
            title: DEFAULT_TITLE,
            createdDate: new Date().getTime(),
            body: PLACEHOLDER_TEXT,
            fontFamily: DEFAULT_FONT_FAMILY,
            stationaryId: DEFAULT_STATIONARY_URL,
            sentDate: 0,
            authorization: AUTH_PRIVATE,
            lastModifiedTimestamp: new Date().getTime(),
            fontFamilySelectOpen: false,
            stationary: DEFAULT_STATIONARY_URL,
            autoSaveTime: 0,
            isSavingDocument: false,
            shareModalOpen: false,
            imageUrl: undefined,
        });
        document.getElementById("letterTextEditor").value = PLACEHOLDER_TEXT;
        document.getElementById("documentTitleInput").value = DEFAULT_TITLE;
    }

    render() {
        if (this.state.redirectToShare) {
            let redirectURL = "/share/" + this.state.id;
            return (
                <Navigate to={redirectURL} />
            );
        }
        let fontOptions = this.generateFontOptions();
        let autoSaveDate = new Date(this.state.lastModifiedTimestamp);
        let time = autoSaveDate.toLocaleTimeString('en-US');
        let date = autoSaveDate.toLocaleDateString('en-US');
        let dateString = time + " " + date;
        let shareButton = [];
        if (this.state.id) {
            shareButton.push(
                <div key="letter-editor-share-button" className="toolbar-button-wrapper">
                    <button className="toolbar-button" onClick={this.redirectToShare}>Share</button>
                </div>
            )
        }
        return (
            <div>
                <AppHeader resetLetterEditor={this.resetLetterEditor}></AppHeader>
                <div className="letter-view-container">
                    <VerticalMenu></VerticalMenu>
                    <div className="page-loading-spinner" hidden={!this.state.isLoadingPage}>
                        <div className="page-loading-spinner-container">
                            <CircularProgress size="5rem" />
                        </div>
                    </div>
                    <div className="letter-editor" hidden={this.state.isLoadingPage}>
                        <div className="letter-view-import-text-container" hidden={!this.state.imageUrl}>
                            This letter was imported from an image, see the original image by clicking <a className="view-import-image-anchor" href={this.state.imageUrl}>here</a>
                        </div>
                        <div className="text-editor-container">
                            <textarea 
                                id="letterTextEditor" 
                                className="letter-editor-textarea" 
                                onKeyUp={this.updateText} 
                                placeholder={PLACEHOLDER_TEXT}>
                            </textarea> 
                        </div>
                        <div className="text-editor-preview-container">
                            <div className="preview-background" style={{ backgroundImage: `url(${this.state.stationary})` }}>
                                <div className="preview-content" style={{ fontFamily: `${this.state.fontFamily}` }}>
                                    {this.state.body}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="letter-editor-toolbar">
                        <div className="toolbar-container toolbar-header">Toolbar</div>
                        <div className="toolbar-container font-toolbar-select-container">
                            <div className="toolbar-select-label">Title</div>
                            <div className="title-input-container">
                                <input id="documentTitleInput" type="text" className="title-input"></input>
                            </div>
                        </div>
                        <div className="toolbar-container font-toolbar-select-container">
                            <div className="toolbar-select-label">Font</div>
                            <div className="font-select">
                                <div className="font-family-select" onClick={this.toggleFontFamilySelect}>
                                    <div className="font-family-select-value" style={{ fontFamily: `${this.state.fontFamily}` }}>{this.state.fontFamily}</div>
                                    <div className="font-family-select-arrow"><ArrowDropDownIcon /></div>
                                </div>
                                <div className="font-toolbar-dropdown" hidden={!this.state.fontFamilySelectOpen}>
                                    {fontOptions}
                                </div>
                            </div>
                        </div>
                        <div className="toolbar-container stationary-toolbar-select-container">
                            <div className="toolbar-select-label">Stationary</div>
                            <div className="stationary-select">
                                {this.state.stationaryOptions}
                            </div>
                        </div>
                        <div className="toolbar-button-group">
                            <div className="toolbar-button-wrapper">
                                <button className="toolbar-button" onClick={this.save}>Save</button>
                            </div>
                            {shareButton}
                            <div className="loading-spinner-container" hidden={!this.state.isSavingDocument}>
                                <CircularProgress />
                            </div>
                            <div className="auto-save-message">Last Saved at {dateString}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
LetterEditorPage.contextType = userContext;
export default LetterEditorPage;