import React from 'react';
import './../styles/HowItWorksView.css';
import './../App.css';
import howItWorksBackground from "../img/how-it-works.png";

class HowItWorksView extends React.Component {
    render() {
        return (
            <div className="how-it-works-container" id="howItWorksSection" style={{ backgroundImage: `url(${howItWorksBackground})` }}>
            </div>
        )
    }
}

export default HowItWorksView;