import React from 'react';
import './../styles/ShareLetterReceiver.css';
import './../App.css';
import Switch from '@mui/material/Switch';

var PUBLIC_AUTH = "PUBLIC";
var PRIVATE_AUTH = "PRIVATE";

class ShareLetterReceiver extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validEmailAddress: true,
            letterAuth: props.authorization,
            showValidationMessage: false
        }

        this.toggleLetterAuthorization = this.toggleLetterAuthorization.bind(this);
        this.validateAndNext = this.validateAndNext.bind(this);
    }

    toggleLetterAuthorization() {
        // Weird hack because the state is not appropriately set to the property so need to check if state is
        // first set before making the change to the actual component state
        let propForCompare = this.state.letterAuth ? this.state.letterAuth : this.props.authorization;
        this.setState({
            letterAuth: propForCompare === PRIVATE_AUTH ? PUBLIC_AUTH : PRIVATE_AUTH
        });
    }

    validateAndNext() {
        let isEmailValid = this.validateEmail();
        let authVal = this.state.letterAuth ? this.state.letterAuth : this.props.authorization;
        let notifyThemVal = document.getElementById("notifyThemSwitch").value === "on" ? true : false;
        console.log(notifyThemVal);
        if (isEmailValid || (authVal === "PUBLIC")) {
            this.setState({
                showValidationMessage: false
            });
            this.props.setReceiverDetails(document.getElementById("receiverInput").value, authVal, notifyThemVal);
        } else {
            this.setState({
                showValidationMessage: true
            });
        }
    }

    validateEmail() {
        let email = document.getElementById("receiverInput").value;
        if (email) {
            let isValidEmail = this.isValidEmail(email);
            this.setState({validEmailAddress: isValidEmail});
            return isValidEmail;
        } else {
            return false;
        }
    }

    isValidEmail(email) {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    render() {
        let disclaimerText = "This also means anyone who has your URL will be able to read your letter.";
        if (this.props.shareType === "QR_CODE") {
            disclaimerText = "This also means anyone who has your QR Code or URL will be able to read your letter.";
        }
        
        let letterAuthValue = (this.props.authorization !== PRIVATE_AUTH);
        if (this.state.letterAuth) {
            letterAuthValue = (this.state.letterAuth !== PRIVATE_AUTH);
        }
        return (
            <div className="share-menu-section">
                <div className="share-menu-section-header">
                    Select Who You Want To Send Your Letter To
                </div>
                <div className="share-to-user-wrapper">
                    <div className="input-container">
                        <input id="receiverInput" className="login-input page-input" style={{width:'25%'}} placeholder="Enter Email Address" defaultValue={this.props.receiver}></input>
                        <div className="criteria-not-met" hidden={this.state.validEmailAddress}>Invalid, please use a valid email address, e.g. myname@gmail.com</div>
                        <div>
                            <div className="auth-switch-label">Notify Them?</div>
                            <Switch id="notifyThemSwitch"></Switch>
                        </div>
                    </div>
                    <div hidden={this.props.shareType === "SECRET_KEY"}>
                        <div className="share-component-or">OR</div>
                        <div>
                            <div className="auth-switch-label">Enable Public Access</div>
                            <Switch checked={letterAuthValue} onClick={this.toggleLetterAuthorization}></Switch>
                        </div>
                        <div className="share-disclaimer-text">By enabling public access, your receiver will not have to login to view your letter. <b>Disclaimer</b>: {disclaimerText}</div>
                    </div>
                </div>
                <div className="primary-button-wrapper center-button">
                    <button className="primary-button" onClick={this.validateAndNext}>Next</button>
                    <div hidden={!this.state.showValidationMessage} className="modal-message-wrapper">
                        <div className="modal-message failure">
                            Make sure to enter a valid email address or enable public sharing
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShareLetterReceiver;
