class PublicDocumentsService {
    constructor(amplifyRequestService, apiName) {
        this.amplifyRequestService = amplifyRequestService;
        this.apiName = apiName;
        this.singleDocumentCache = {};
    }

    getLetterById(id) {
        if (this.singleDocumentCache[id]){
            return this.singleDocumentCache[id];
        }
        let queryParams = {
            queryStringParameters: {
                letterId: [id]
            }
        }
        this.singleDocumentCache[id] = this.amplifyRequestService.makeRequest(this.apiName, '/public-documents', "GET", queryParams);
        return this.singleDocumentCache[id];
    }
}
export default PublicDocumentsService;