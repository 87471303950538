const fontsImport = [
    "Aguafina Script",
    "Aladin",
    "Alex Brush",
    "Allison",
    "Allura",
    "Amatic SC",
    "Amita",
    "Annie Use Your Telescope",
    "Architects Daughter",
    "Are You Serious",
    "Arizonia",
    "Babylonica",
    "Bad Script",
    "Ballet",
    "Beau Rivage",
    "Berkshire Swash",
    "Beth Ellen",
    "Bilbo",
    "Bilbo Swash Caps",
    "Birthstone",
    "Birthstone Bounce",
    "Bonbon",
    "Bonheur Royale",
    "Butterfly Kids",
    "Calligraffitti",
    "Caramel",
    "Carattere",
    "Caveat",
    "Caveat Brush",
    "Cedarville Cursive",
    "Charm",
    "Charmonman",
    "Cherish",
    "Chilanka",
    "Clicker Script",
    "Comforter",
    "Comforter Brush",
    "Comic Neue",
    "Coming Soon",
    "Condiment",
    "Cookie",
    "Corinthia",
    "Courgette",
    "Covered By Your Grace",
    "Crafty Girls",
    "Damion",
    "Dancing Script",
    "Dawning of a New Day",
    "Dekko",
    "Delius",
    "Delius Swash Caps",
    "Delius Unicase",
    "Devonshire",
    "Dokdo",
    "Dr Sugiyama",
    "Eagle Lake",
    "East Sea Dokdo",
    "Edu NSW ACT Foundation",
    "Edu QLD Beginner",
    "Edu SA Beginner",
    "Edu TAS Beginner",
    "Edu VIC WA NT Beginner",
    "Engagement",
    "Ephesis",
    "Estonia",
    "Euphoria Script",
    "Explora",
    "Felipa",
    "Festive",
    "Fleur De Leah",
    "Fondamento",
    "Fuggles",
    "Fuzzy Bubbles",
    "Gaegu",
    "Gamja Flower",
    "Give You Glory",
    "Gloria Hallelujah",
    "Gochi Hand",
    "Grand Hotel",
    "Grape Nuts",
    "Great Vibes",
    "Grechen Fuemen",
    "Grey Qo",
    "Gwendolyn",
    "Hachi Maru Pop",
    "Handlee",
    "Herr Von Muellerhoff",
    "Hi Melody",
    "Homemade Apple",
    "Hurricane",
    "Imperial Script",
    "Indie Flower",
    "Ingrid Darling",
    "Inspiration",
    "Island Moments",
    "Italianno",
    "Itim",
    "Jim Nightshade",
    "Julee",
    "Just Another Hand",
    "Just Me Again Down Here",
    "Kalam",
    "Kaushan Script",
    "Kavivanar",
    "Kings",
    "Klee One",
    "Kolker Brush",
    "Kristi",
    "La Belle Aurore",
    "Lakki Reddy",
    "Lavishly Yours",
    "League Script",
    "Leckerli One",
    "Licorice",
    "Liu Jian Mao Cao",
    "Long Cang",
    "Love Light",
    "Loved by the King",
    "Lovers Quarrel",
    "Luxurious Script",
    "Ma Shan Zheng",
    "Mali",
    "Mansalva",
    "Marck Script",
    "Mea Culpa",
    "Meddon",
    "Meie Script",
    "Meow Script",
    "Merienda",
    "Merienda One",
    "Miss Fajardose",
    "Molle",
    "Monsieur La Doulaise",
    "MonteCarlo",
    "Montez",
    "Moon Dance",
    "Mr Bedfort",
    "Mr Dafoe",
    "Mr De Haviland",
    "Mrs Saint Delafield",
    "Mrs Sheppards",
    "Ms Madi",
    "My Soul",
    "Nanum Brush Script",
    "Nanum Pen Script",
    "Neonderthaw",
    "Nerko One",
    "Neucha",
    "Niconne",
    "Norican",
    "Nothing You Could Do",
    "Ole",
    "Oooh Baby",
    "Over the Rainbow",
    "Pacifico",
    "Pangolin",
    "Parisienne",
    "Passions Conflict",
    "Patrick Hand",
    "Patrick Hand SC",
    "Permanent Marker",
    "Petemoss",
    "Petit Formal Script",
    "Pinyon Script",
    "Praise",
    "Princess Sofia",
    "Puppies Play",
    "Quintessential",
    "Qwigley",
    "Qwitcher Grypen",
    "Rancho",
    "Redressed",
    "Reenie Beanie",
    "Rochester",
    "Rock Salt",
    "Romanesco",
    "Rouge Script",
    "Ruge Boogie",
    "Ruthie",
    "Sacramento",
    "Sassy Frass",
    "Satisfy",
    "Schoolbell",
    "Sedgwick Ave",
    "Sedgwick Ave Display",
    "Send Flowers",
    "Shadows Into Light",
    "Shadows Into Light Two",
    "Shalimar",
    "Short Stack",
    "Smooch",
    "Sofia",
    "Splash",
    "Square Peg",
    "Sriracha",
    "Stalemate",
    "Style Script",
    "Sue Ellen Francisco",
    "Sunshiney",
    "Swanky and Moo Moo",
    "Tangerine",
    "Tapestry",
    "The Girl Next Door",
    "The Nautigal",
    "Tillana",
    "Twinkle Star",
    "Updock",
    "Vibur",
    "Vujahday Script",
    "Waiting for the Sunrise",
    "Walter Turncoat",
    "Water Brush",
    "Waterfall",
    "Whisper",
    "WindSong",
    "Yellowtail",
    "Yesteryear",
    "Yomogi",
    "Zeyada",
    "Zhi Mang Xing"
]
  
export default fontsImport