import React from 'react';
import './../styles/ShareLetterDate.css';
import './../App.css';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

class ShareLetterDate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            showTimeErrorMessage: false
        }

        this.validateAndNext = this.validateAndNext.bind(this);
    }

    validateAndNext() {
        if (this.state.date){
            let today = new Date();
            // backdating today by 1 day to ensure sharing "today" works
            today.setDate(today.getDate() - 1);
            if ((today.getTime() - this.state.date.getTime()) > 0) {
                // Don't let them choose a date in the past
                this.setState({
                    showTimeErrorMessage: true
                });
            } else {
                this.props.setDate(this.state.date);
                this.setState({
                    showTimeErrorMessage: false
                });
            }
        }
    }

    saveDate(event, context) {
        this.setState({
            date: event
        });
    }

    render() {
        return (
            <div className="share-menu-section">
                <div className="share-menu-section-header">
                    Select When You Want To Send It
                </div>
                <div className="share-date-container">
                    <div className="date-text">If you choose a date in the future, then your receiver will have access to your letter on the day you choose automatically.</div>
                    <div>
                        <div className="date-picker-container">
                            <DatePicker selected={this.state.date} onChange={this.saveDate.bind(this)} />
                        </div>
                    </div>
                    <div hidden={!this.state.showTimeErrorMessage} className="modal-message-wrapper">
                        <div className="modal-message failure share-message">
                            Please choose either today or a date in the future
                        </div>
                    </div>
                </div>
                <div className="primary-button-wrapper center-button">
                    <button className="primary-button" onClick={this.validateAndNext}>Next</button>
                </div>
            </div>
        )
    }
}

export default ShareLetterDate;
